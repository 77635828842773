import { animationTypeThree } from "./animationTypeThree";

export const moveAnimation = (timeline,inputArray,moveXBy, moveYBy,index) => {
    let target1 = inputArray[index].current;
    let timeFactor = 1000;
    const height = 90;
    const width = 90;
        
    // box1 - moveY+1 -> moveX+4 -> moveY-1
    timeline.add(animationTypeThree(target1,moveXBy,width,moveYBy,height,timeFactor))

    return timeline;
    
}