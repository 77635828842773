import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { changeMenuState } from '../../../actions/changeMenuState';

export const HamburgerMenu = () => {

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const location = useLocation();
  
    const handleMenuOpen = () => {
        setIsMenuOpen( prevState => !prevState )
        dispatch(changeMenuState())
    };

    const isLocationApp = () => {
    return location.pathname === '/' || 
        location.pathname === '/algorithms' ||
        location.pathname === '/about-us' ||
        location.pathname === '/blogs';
    }

    const dispatch = useDispatch();

    return(
        <div onClick={handleMenuOpen}
            className={ isLocationApp() ? 'flex-item hamburger-menu hamburger-spacing' : 'flex-item hamburger-menu hamburger-spacing-app' }
        >
            <svg aria-label="Open Navigation" className={isMenuOpen?"hamburger-active":"hamburger"} viewBox="0 0 100 100" width="80">
                <path className="hamburger-line hamburger-top" d="m 30,33 h 40 c 0,0 9.044436,-0.654587 9.044436,-8.508902 0,-7.854315 -8.024349,-11.958003 -14.89975,-10.85914 -6.875401,1.098863 -13.637059,4.171617 -13.637059,16.368042 v 40"></path>
                <path className="hamburger-line hamburger-middle" d="m 30,50 h 40"></path>
            </svg>
        </div>
    )
}