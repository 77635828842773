import logo from './logo.svg';
import { Link, useLocation } from 'react-router-dom';

export const GeekosophersLogo = () => {

    const location = useLocation();

    const isLocationApp = () => {
        return location.pathname === '/' || 
            location.pathname === '/algorithms' ||
            location.pathname === '/about-us' ||
            location.pathname === '/blogs';
    }

    return(
        <div className={ isLocationApp() ? 'flex-item flex-item1' : 'flex-item flex-item1-app' }>
            <Link to='/'>
                <img src={logo} />
            </Link>
        </div>
    )
}