import { backgroundColorChange, backgroundColorChangeFlow } from '../backgroundAnimation/backgroundColorChange';
import { borderColorChange } from '../borderAnimation/borderColorChange';
import { ERROR, LIGHT_PURPLE, MINIMUM,SUCCESS } from '../colorScheme';
import TreeNode from '../tree/TreeNode';
import { traverseTreeRefsUtil } from '../tree/traverseTreeRefsUtil';

const findMinNode = (root,timeline,refLines) => {
    
    backgroundColorChange(timeline,refLines[2],[MINIMUM,LIGHT_PURPLE],1000,'+=0')
    backgroundColorChange(timeline,root.value.childNodes[root.value.childNodes.length-1],[MINIMUM],1000,'-=2000')
    borderColorChange(timeline,root.value.childNodes[root.value.childNodes.length-1],[MINIMUM],1000,'-=2000')

    // Traverse the left subtree until the leftmost leaf node is found
    while (root.left !== null) {
        let childNodes = root.value.childNodes
        backgroundColorChange(timeline,refLines[3],[MINIMUM,SUCCESS,LIGHT_PURPLE],1000,'+=0')
        backgroundColorChangeFlow(timeline,childNodes[1],MINIMUM,childNodes[0].style.width,1000,'-=2000')
        backgroundColorChange(timeline,refLines[4],[MINIMUM,LIGHT_PURPLE],1000,'+=0')
        root = root.left;
        backgroundColorChange(timeline,root.value.childNodes[root.value.childNodes.length-1],[MINIMUM],1000,'-=2000')
        borderColorChange(timeline,root.value.childNodes[root.value.childNodes.length-1],[MINIMUM],1000,'-=2000')
    }
    backgroundColorChange(timeline,refLines[3],[MINIMUM,ERROR,LIGHT_PURPLE],1000,'+=0')
    
    // The leftmost leaf node is the minimum node
    backgroundColorChange(timeline,root.value.childNodes[root.value.childNodes.length-1],[MINIMUM,SUCCESS],1000,'+=0')
    borderColorChange(timeline,root.value.childNodes[root.value.childNodes.length-1],[MINIMUM,SUCCESS],1000,'-=2000')
    backgroundColorChange(timeline,refLines[5],[SUCCESS],1000,'-=1000')
    return root.val;
}

export const minimumNode = (refs,timeline,refLines) => {

    const listOfNodes = refs.current.childNodes;

    //Base Case
    backgroundColorChange(timeline,refLines[0],[MINIMUM],1000,'+=0')
    if(listOfNodes.length===0){
        backgroundColorChange(timeline,refLines[0],[SUCCESS],1000,'+=0')
        backgroundColorChange(timeline,refLines[1],[SUCCESS],1000,'+=0')
        return;
    }
    backgroundColorChange(timeline,refLines[0],[ERROR,LIGHT_PURPLE],1000,'+=0')

    let root = new TreeNode(listOfNodes[0]);
    traverseTreeRefsUtil(root,listOfNodes)
    findMinNode(root,timeline,refLines)
}