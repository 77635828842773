import { backgroundColorChange, backgroundColorChangeFlow, backgroundColorChangeReverseFlow } from '../../backgroundAnimation/backgroundColorChange';
import { borderColorChange } from '../../borderAnimation/borderColorChange';
import { changeAlgorithmOutputText } from '../../changeTextAnimation/changeAlgorithmOutputText';
import { ERROR, LIGHT_PURPLE, MINIMUM,SUCCESS } from '../../colorScheme';
import TreeNode from '../../tree/TreeNode';
import { traverseTreeRefsUtil } from '../../tree/traverseTreeRefsUtil';

const preOrder = (root,timeline,refLines,algorithmOutputRef) => {

    const childNodes = root.value.childNodes;

    backgroundColorChange(timeline,refLines[0],[MINIMUM,LIGHT_PURPLE],1000,'+=0')
    borderColorChange(timeline,childNodes[childNodes.length-1],[MINIMUM],1000,'-=2000')

    if(algorithmOutputRef.current)
        changeAlgorithmOutputText(timeline,algorithmOutputRef.current,childNodes[childNodes.length-1].innerHTML)
    borderColorChange(timeline,childNodes[childNodes.length-1],[SUCCESS],1000,'+=0')
    backgroundColorChange(timeline,childNodes[childNodes.length-1],[SUCCESS],1000,'-=1000')
    backgroundColorChange(timeline,refLines[5],[SUCCESS,LIGHT_PURPLE],1000,'-=1000')
    
    backgroundColorChange(timeline,refLines[3],[MINIMUM],1000,'+=0')
    if (root.left!==null) {
        backgroundColorChange(timeline,refLines[3],[SUCCESS,LIGHT_PURPLE],1000,'+=0')
        backgroundColorChangeFlow(timeline,childNodes[1],MINIMUM,childNodes[0].style.width,1000,'-=2000')
        backgroundColorChange(timeline,refLines[4],[MINIMUM,LIGHT_PURPLE],1000,'-=1000')
        preOrder(root.left,timeline,refLines,algorithmOutputRef);
        backgroundColorChangeReverseFlow(timeline,childNodes[1],MINIMUM,childNodes[0].style.width,1000,'+=0')
    } else{
        backgroundColorChange(timeline,refLines[3],[ERROR,LIGHT_PURPLE],1000,'+=0')
    }
    
    backgroundColorChange(timeline,refLines[6],[MINIMUM],1000,'+=0')
    if (root.right!=null) {
        backgroundColorChange(timeline,refLines[6],[SUCCESS,LIGHT_PURPLE],1000,'+=0')
        backgroundColorChangeFlow(timeline,childNodes[childNodes.length-2],MINIMUM,childNodes[childNodes.length-3].style.width,1000,'-=2000')
        backgroundColorChange(timeline,refLines[7],[MINIMUM,LIGHT_PURPLE],1000,'-=1000')
        preOrder(root.right,timeline,refLines,algorithmOutputRef);
        backgroundColorChangeReverseFlow(timeline,childNodes[childNodes.length-2],MINIMUM,childNodes[0].style.width,1000,'+=0')
    } else{
        backgroundColorChange(timeline,refLines[6],[ERROR,LIGHT_PURPLE],1000,'+=0')
    }
}

export const preOrderTraversal = (refs,timeline,refLines,algorithmOutputRef) => {

    const listOfNodes = refs.current.childNodes;

    if(listOfNodes.length==0)
        return

    let root = new TreeNode(listOfNodes[0]);
    traverseTreeRefsUtil(root,listOfNodes)
    preOrder(root,timeline,refLines,algorithmOutputRef)

    backgroundColorChange(timeline,refLines[8],[MINIMUM,LIGHT_PURPLE],1000,'+=0')
}