import { useSelector, useDispatch } from "react-redux";
import { useEffect, useContext } from "react";
import { resetStep } from "../actions/setStep";
import AnimationContext from "../context/AnimationContext";

export const useStepControlAnimation = () => {

    const stepNumber = useSelector(state => state.stepState.stepNumber)
    const dispatch = useDispatch()
    const timeline = useContext(AnimationContext);

    const handleTheStep = (stepNumber) => {

        if(stepNumber==0){
            timeline.seek(0)
            return;
        } else if(stepNumber>=timeline.children.length){
            dispatch(resetStep())
        }

        else if(stepNumber>0 && stepNumber!=null){
            let activeChildren = timeline.children[stepNumber]
            let timelineOffset = activeChildren.timelineOffset
            let duration = activeChildren.duration

            timeline.seek(timelineOffset+duration)
        }

    }

    useEffect(() => {
        if(timeline){
            handleTheStep(stepNumber)
        }
    },[stepNumber])
}