export const seekActiveChild = (timeline) => {
        
    const currentTime = timeline.currentTime;
    const childAnimations = timeline.children;

    for (let i = 0; i < childAnimations.length; i++) {
        const animation = childAnimations[i];
        if (currentTime >= animation.timelineOffset && currentTime <= animation.timelineOffset + animation.duration) {
            return i;
        }
    }
    
};