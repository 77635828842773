import { useContext } from "react";
import AlgorithmContext from "../../../../../context/AlgorithmContext";

export const MinimumNodeAnimationContent = () => {
    
    const refLines = useContext(AlgorithmContext);

    return(
        <div>
            <h2 className='description-title'>Minimum Node of BST</h2>
            <div className='description-content-inside description-content-box code'>

                <div className='min-procedure-line'>procedure <span className='primary-purple'>findMinNode</span>(root):</div>
                <div className='indent-1 min-if-loop'><span ref={refLines[0]}>if root is null</span></div>
                <div className='indent-2'><span ref={refLines[1]}>return <span className='primary-pink'>null</span></span></div>
                <div className='indent-1'><span ref={refLines[2]}>current = root</span></div>
                <div className='indent-1 min-while-loop'><span ref={refLines[3]}>while current.left is not null</span></div>
                <div className='indent-2'><span ref={refLines[4]}>current = current.left</span></div>
                <div className='indent-1'><span ref={refLines[5]}>return <span className='primary-pink'>current</span></span></div>
                <div>end procedure</div>
            </div>
        </div>
    )
}