import { createRef, useEffect, useState } from 'react'
import './InsertionSort.css'
import { useHandleAnimation } from '../../../../../customHooks/useHandleAnimation'
import { useDispatch, useSelector } from 'react-redux'
import { usePlayPauseAnimation } from '../../../../../customHooks/usePlayPauseAnimation'
import { useTimelineUpdate } from '../../../../../customHooks/useTimelineUpdate'
import { useStepControlAnimation } from '../../../../../customHooks/useStepControlAnimation'
import { setCustomInput } from '../../../../../actions/setCustomInput'

export const InsertionSortAnimation = () => {

    const customInput = useSelector(state => state.customInput)
    const [inputArray, setInputArray] = useState([]);
    const references = inputArray.map(() => createRef())
    const dispatch = useDispatch()

    useHandleAnimation(references)
    usePlayPauseAnimation()
    useTimelineUpdate()
    useStepControlAnimation()

    useEffect(() => {
        if(customInput!=='')
            setInputArray(JSON.parse(JSON.parse(customInput).firstInput))
    },[customInput])

    useEffect(() => {
        dispatch(setCustomInput(JSON.stringify({
            firstInput: '[54,25,12,22,11]'
        })))
    },[])
    
    return(
        <div className='animation-world'>
            <div className='centering-container scaling-for-mobile'>
                <div className='box-container'>
                    {
                        inputArray.map( (elem,index) => (
                            <div key={index} id={index} ref={references[index]} className='box'>
                                <div className='box-elem'>
                                    {elem}
                                </div>
                            </div>
                        ))
                    }
                    <div className="pointer"></div>
                </div>
            </div>            
        </div>
    )
}