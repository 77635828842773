import { createRef, useEffect, useState } from 'react'
import './LowestCommonAncestor.css'
import { useHandleAnimation } from '../../../../../customHooks/useHandleAnimation'
import { useDispatch, useSelector } from 'react-redux'
import { usePlayPauseAnimation } from '../../../../../customHooks/usePlayPauseAnimation'
import { useTimelineUpdate } from '../../../../../customHooks/useTimelineUpdate'
import { useStepControlAnimation } from '../../../../../customHooks/useStepControlAnimation'
import { setCustomInput } from '../../../../../actions/setCustomInput'
import BinarySearchTreeComponent from '../../BinarySearchTreeComponent'

export const LowestCommonAncestorAnimation = () => {

    const customInput = useSelector(state => state.customInput)
    const [inputArray, setInputArray] = useState([]);
    const [nodes, setNodes] = useState({p: '', q: ''})
    const dispatch = useDispatch();
    const containerRef = createRef();

    useHandleAnimation({containerRef,nodes})
    usePlayPauseAnimation()
    useTimelineUpdate()
    useStepControlAnimation()

    useEffect(() => {
        if(customInput!==''){
            const parsedCustomInput = JSON.parse(customInput)
            setInputArray(JSON.parse(parsedCustomInput.firstInput))
            setNodes({
                p: JSON.parse(parsedCustomInput.secondInput),
                q: JSON.parse(parsedCustomInput.thirdInput)
            })
        }
    },[customInput])

    useEffect(() => {
        dispatch(setCustomInput(JSON.stringify({
            firstInput: '[50,30,70,20,40,60,80,25,32,55,75]',
            secondInput: '60',
            thirdInput: '75'
        })))
    },[])
    
    return(
        <div className='animation-world'>
            <div className='tree-box'>
                <BinarySearchTreeComponent containerRef={containerRef} inputArray={inputArray} />
            </div>            
        </div>
    )
}