import { useContext, useState } from "react";
import { DescriptionSubHeader } from "./DescriptionSubHeader";
import './DescriptionWindow.css'
import AnimationContext from "../../context/AnimationContext";
import { seekActiveChild } from "../../util/seekActiveChild";
import { useDispatch } from "react-redux";
import { setStep } from "../../actions/setStep";

export const DescriptionWindow = (props) => {
    
    const [descriptionWindowSubHeader, setDescriptionWindowSubHeader] = useState('Description');
    const timeline = useContext(AnimationContext);
    const dispatch = useDispatch();

    const handleAlgorithmTabClick = () => {
        const activeChildIndex = seekActiveChild(timeline)
        dispatch(setStep(activeChildIndex))
        setDescriptionWindowSubHeader('Execution');
    }
    
    if(props.size.x>150){
        return(
            <div className='description'>
                <div className='sub-header'>
                    <div 
                        onClick={() => setDescriptionWindowSubHeader('Description')} 
                        className={`sub-header-div ${descriptionWindowSubHeader==='Description' && 'active-sub-header'}`}
                    >
                        Description
                    </div>
                    <div 
                        onClick={handleAlgorithmTabClick}
                        className={`sub-header-div ${descriptionWindowSubHeader==='Execution' && 'active-sub-header'}`}
                    >
                        Execution
                    </div>
                </div>
                <DescriptionSubHeader descriptionWindowSubHeader={descriptionWindowSubHeader} />
            </div>
        )
    }
}