import AlgoHighlighting from '../../images/algorithm-highlighting@2x.png'
import CustomInputs from '../../images/custom-inputs@2x.png'
import RealTimeStatusUpdates from '../../images/real-time-updates@2x.png'
import AnimationControls from '../../images/animation-controls@2x.png'
import AlgoDescription from '../../images/algorithm-description@2x.png'
import ExampleCases from '../../images/example@2x.png'
import JavaSolutions from '../../images/java-solution@2x.png'

export const features = [
    {
        title: 'Algorithm Highlighting',
        content: 'Gain deeper insights into each step of the algorithm with enhanced highlighting, providing better understanding and clarity.',
        image: AlgoHighlighting,
        reverseOrder: false
    }, {
        title: 'Custom Inputs',
        content: 'Experiment with personalised input cases, offering a space to explore and test the algorithm with real-world scenarios.',
        image: CustomInputs,
        reverseOrder: true
    }, {
        title: 'Real-time Status Updates',
        content: "Stay informed with live calculations during animation, ensuring you're always in sync with the algorithm's progress.",
        image: RealTimeStatusUpdates,
        reverseOrder: false 
    }, {
        title: 'Animation Controls',
        content: 'Take charge of your learning experience with comprehensive animation controls:',
        image: AnimationControls,
        reverseOrder: true 
    }, {
        title: 'Algorithm Descriptions',
        content: 'Grasp the fundamentals with concise algorithm descriptions, providing essential context for effective learning.',
        image: AlgoDescription,
        reverseOrder: false
    }, {
        title: 'Example Cases',
        content: 'Explore various input and output scenarios, offering practical examples to reinforce understanding.',
        image: ExampleCases,
        reverseOrder: true
    }, {
        title: 'Java Solutions',
        content: 'Access the actual implementation of the algorithm in Java, enabling hands-on learning and application in real-world projects.',
        image: JavaSolutions,
        reverseOrder: false
    }
    
]