const defaultStepState = { 
    stepNumber: 0, 
    pauseStepNumber: 0 
}

export const stepStateReducer = (state=defaultStepState, action) => {
    switch(action.type){
        case 'SET_PAUSE_STEP':
            return {...state, pauseStepNumber: action.pauseStepNumber}
        case 'SET_STEP':
            return {...state, stepNumber: action.stepNumber}
        case 'NEXT_STEP':
            return {...state, stepNumber: state.stepNumber+1}
        case 'PREVIOUS_STEP':
            return state.stepNumber>0 ? state.stepNumber-1 : 0
        case 'RESET_STEP':
            return defaultStepState
        default:
            return state
    }
}