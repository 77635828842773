import { backgroundColorChange, backgroundColorChange2dArray } from "../backgroundAnimation/backgroundColorChange";
import { changeText } from "../changeTextAnimation/changeText";
import { SUCCESS,MINIMUM, LIGHT_PURPLE } from "../colorScheme";
import { moveWindowToPosition } from "../translationAnimation/movePointer/movePointerToNextPosition";

const processingFirstColumn = (timeline,refLines,referencesForInputMatrix,referencesForResultMatrix,memo,referenceProcessingElement,referenceCalculationElement,m) => {
    
    for (let i = 1; i < m; i++) {
        backgroundColorChange(timeline,refLines[1],[MINIMUM,LIGHT_PURPLE],1000,'+=0') 
        backgroundColorChange(timeline,refLines[2],[MINIMUM,LIGHT_PURPLE],1000,'+=0') 

        let targetElement = referencesForInputMatrix[i][0].current.childNodes[0]

        // status info
        changeText(timeline,referenceProcessingElement.current,null,`memo[${i}][0]`,100,'-=2000')
        changeText(timeline,referenceCalculationElement.current,null,`memo[${i}][0] = memo[${i-1}][0] + arr[${i}][0] = ${memo[i-1][0]} + ${targetElement.innerHTML} = ${parseInt(memo[i - 1][0]) + parseInt(targetElement.innerHTML)}`,100,'-=2000')
        
        moveWindowToPosition(timeline,'.mps-progress-window',0,i,100,'-=2000')
        
        memo[i][0] = parseInt(memo[i - 1][0]) + parseInt(targetElement.innerHTML);
        
        backgroundColorChange2dArray(timeline,referencesForInputMatrix,i,0,[MINIMUM,SUCCESS],1000,'-=2000')
        backgroundColorChange2dArray(timeline,referencesForResultMatrix,i,0,[MINIMUM,SUCCESS],1000,'-=2000')
        
        changeText(timeline,referencesForResultMatrix[i][0].current.childNodes[0],'0',memo[i][0],100,'-=2000')
        
    }

}

const processingFirstRow = (timeline,refLines,referencesForInputMatrix,referencesForResultMatrix,memo,referenceProcessingElement,referenceCalculationElement,n) => {
    for (let j = 1; j < n; j++) {
        backgroundColorChange(timeline,refLines[3],[MINIMUM,LIGHT_PURPLE],1000,'+=0')
        backgroundColorChange(timeline,refLines[4],[MINIMUM,LIGHT_PURPLE],1000,'+=0')

        let targetElement = referencesForInputMatrix[0][j].current.childNodes[0]
        
        changeText(timeline,referenceCalculationElement.current,null,`memo[0][${j}] = memo[0][${j-1}] + arr[0][${j}] = ${memo[0][j-1]} + ${targetElement.innerHTML} = ${parseInt(memo[0][j - 1]) + parseInt(targetElement.innerHTML)}`,100,'-=2000')
        changeText(timeline,referenceProcessingElement.current,null,`memo[0][${j}]`,100,'-=2000')
        
        moveWindowToPosition(timeline,'.mps-progress-window',j,0,100,'-=2000')
        
        memo[0][j] = parseInt(memo[0][j - 1]) + parseInt(targetElement.innerHTML);
        
        backgroundColorChange2dArray(timeline,referencesForInputMatrix,0,j,[MINIMUM,SUCCESS],1000,'-=2000')
        backgroundColorChange2dArray(timeline,referencesForResultMatrix,0,j,[MINIMUM,SUCCESS],1000,'-=2000')
        changeText(timeline,referencesForResultMatrix[0][j].current.childNodes[0],'0',memo[0][j],100,'-=2000')
    }
}

const processingRemainingMatrix = (timeline,refLines,referencesForInputMatrix,referencesForResultMatrix,memo,referenceProcessingElement,referenceCalculationElement,m,n) => {
    for (let i = 1; i < m; i++) {
        backgroundColorChange(timeline,refLines[5],[MINIMUM,LIGHT_PURPLE],1000,'+=0')
        for (let j = 1; j < n; j++) {
            backgroundColorChange(timeline,refLines[6],[MINIMUM,LIGHT_PURPLE],1000,'+=0')
            backgroundColorChange(timeline,refLines[7],[MINIMUM,LIGHT_PURPLE],1000,'+=0')

            let targetElement = referencesForInputMatrix[i][j].current.childNodes[0];
            
            changeText(timeline,referenceCalculationElement.current,null,`memo[${i}][${j}] = min(memo[${i-1}][${j}],memo[${i}][${j-1}]) + arr[${i}][${j}] = min(${memo[i - 1][j]},${memo[i][j - 1]}) + ${targetElement.innerHTML} = ${Math.min(parseInt(memo[i - 1][j]), parseInt(memo[i][j - 1])) + parseInt(targetElement.innerHTML)}`,100,'-=2000')
            changeText(timeline,referenceProcessingElement.current,null,`memo[${i}][${j}]`,100,'-=2000')
            
            memo[i][j] = Math.min(parseInt(memo[i - 1][j]), parseInt(memo[i][j - 1])) + parseInt(targetElement.innerHTML);
            
            moveWindowToPosition(timeline,'.mps-progress-window',j,i,100,'-=2000')
            
            backgroundColorChange2dArray(timeline,referencesForInputMatrix,i,j,[MINIMUM,SUCCESS],1000,'-=2000')
            backgroundColorChange2dArray(timeline,referencesForResultMatrix,i,j,[MINIMUM,SUCCESS],1000,'-=2000')
            changeText(timeline,referencesForResultMatrix[i][j].current.childNodes[0],'0',memo[i][j],100,'-=2000')

        }
    }
    backgroundColorChange(timeline,refLines[8],[SUCCESS],1000,'+=0')
}


export const minimumPathSum = (refs,timeline,refLines) => {
    let {referencesForInputMatrix,referencesForResultMatrix,referenceProcessingElement,referenceCalculationElement} = {...refs}
            
    if (!referencesForInputMatrix || referencesForInputMatrix.length === 0) return;

    const m = referencesForInputMatrix.length;
    const n = referencesForInputMatrix[0].length;

    const memo = new Array(m).fill(null).map(() => new Array(n).fill(0));

    memo[0][0] = referencesForInputMatrix[0][0].current.childNodes[0].innerHTML;
    backgroundColorChange(timeline,refLines[0],[MINIMUM,LIGHT_PURPLE],1000,'+=0')

    // status Info
    changeText(timeline,referencesForResultMatrix[0][0].current.childNodes[0],'0',referencesForInputMatrix[0][0].current.childNodes[0].innerHTML,100,'-=2000')
    changeText(timeline,referenceProcessingElement.current,null,'memo[0][0]',100,'-=2000')
    changeText(timeline,referenceCalculationElement.current,null,`memo[0][0] = arr[0][0] = ${referencesForInputMatrix[0][0].current.childNodes[0].innerHTML}`,100,'-=2000')

    backgroundColorChange2dArray(timeline,referencesForInputMatrix,0,0,[MINIMUM,SUCCESS],1000,'-=2000')
    backgroundColorChange2dArray(timeline,referencesForResultMatrix,0,0,[MINIMUM,SUCCESS],1000,'-=2000')

    // processing column 0 
    processingFirstColumn(timeline,refLines,referencesForInputMatrix,referencesForResultMatrix,memo,referenceProcessingElement,referenceCalculationElement,m)

    // processing row 0 
    processingFirstRow(timeline,refLines,referencesForInputMatrix,referencesForResultMatrix,memo,referenceProcessingElement,referenceCalculationElement,n)

    // processing remaning matrix 
    processingRemainingMatrix(timeline,refLines,referencesForInputMatrix,referencesForResultMatrix,memo,referenceProcessingElement,referenceCalculationElement,m,n)
}