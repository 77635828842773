import { useSelector } from "react-redux";

export const CustomInputBorder = () => {

    const visibilityToggleForCustomInputs = useSelector(state => state.visibilityToggleForCustomInputs)

    if(visibilityToggleForCustomInputs)
        return(<div className='custom-input-border'></div>);

    return null;
}