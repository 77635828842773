const pi = Math.PI;

export const createEdge = (theta,x1,y1,edgeLength,directionOfEdge) => {
    
    const rotateBy = directionOfEdge==='left' ? pi/2 + theta : pi/2 - theta;
    const edge = document.createElement('div');

    edge.className = `bst-edge ${directionOfEdge}`;
    edge.style.top = `${y1}rem`;
    edge.style.left = `${x1}rem`;
    edge.style.transformOrigin = 'top left';
    edge.style.transform = `rotate(${rotateBy}rad)` 
    edge.style.width = edgeLength
    return edge;
}