import React from "react";
import { useLocation } from 'react-router-dom';
import { SelectionSortDescriptionContent } from "../../pages/AnimationsPage/SortingAlgorithms/SelectionSortPage/Content/SelectionSortDescriptionContent";
import { BubbleSortDescriptionContent } from "../../pages/AnimationsPage/SortingAlgorithms/BubbleSortPage/Content/BubbleSortDescriptionContent";
import { InsertionSortDescriptionContent } from "../../pages/AnimationsPage/SortingAlgorithms/InsertionSortPage/Content/InsertionSortDescriptionContent";
import { MinimumPathSumDescriptionContent } from "../../pages/AnimationsPage/MinimumPathSum/Content/MinimumPathSumDescriptionContent";
import { MinimumNodeDescriptionContent } from "../../pages/AnimationsPage/TreeDataStructure/MinimumNode/Content/MinimumNodeDescriptionContent";
import { InOrderDescriptionContent } from "../../pages/AnimationsPage/TreeDataStructure/TreeTraversal/InOrder/Content/InOrderDescriptionContent";
import { PreOrderDescriptionContent } from "../../pages/AnimationsPage/TreeDataStructure/TreeTraversal/PreOrder/Content/PreOrderDescriptionContent";
import { PostOrderDescriptionContent } from "../../pages/AnimationsPage/TreeDataStructure/TreeTraversal/PostOrder/Content/PostOrderDescriptionContent";
import { LowestCommonAncestorDescriptionContent } from "../../pages/AnimationsPage/TreeDataStructure/LowestCommonAncestor/Content/LowestCommonAncestorDescriptionContent";

export const DescriptionContent = () => {
    
    const location = useLocation();
    const { pathname } = location;
    
    return(
        <div className="description-content-wrapper">
            <div className='description-content'>
                {pathname === '/selection-sort' && <SelectionSortDescriptionContent />}
                {pathname === '/bubble-sort' && <BubbleSortDescriptionContent />}
                {pathname === '/insertion-sort' && <InsertionSortDescriptionContent />}
                {pathname === '/minimum-path-sum' && <MinimumPathSumDescriptionContent />}
                {pathname === '/minimum-node' && <MinimumNodeDescriptionContent />}
                {pathname === '/in-order-traversal' && <InOrderDescriptionContent />}
                {pathname === '/pre-order-traversal' && <PreOrderDescriptionContent />}
                {pathname === '/post-order-traversal' && <PostOrderDescriptionContent />}
                {pathname === '/lowest-common-ancestor' && <LowestCommonAncestorDescriptionContent />}
            </div>
        </div>
    )
}