import './AnimationWindow.css'
import { useLocation } from 'react-router-dom';
import { AnimationFoundation } from '../Animation/AnimationFoundation';
import { useDispatch, useSelector } from 'react-redux';
import { toggleRestart } from '../../actions/toggleRestart';
import { ProgressBar } from './ProgressBar/ProgressBar';
import { AnimationControlWindow } from './AnimationControlWindow/AnimationControlWindow';
import PlaybackSpeedSvg from './svg/playback-speed.svg';
import ResetAnimationSvg from './svg/reset-animation.svg';
import FullscreenSvg from './svg/fullscreen.svg';
import FullscreenExitSvg from './svg/fullscreen-exit.svg';
import { changeStatusInfoState } from '../../actions/changeStatusInfoState';
import ArrowSvg from './svg/arrow.svg';

export const AnimationWindow = (props) => {

    const visibilityToggleForCustomInputs = useSelector(state => state.visibilityToggleForCustomInputs)
    const statusInfoState = useSelector(state => state.statusInfoState)
    const dispatch = useDispatch();
    
    const location = useLocation();
    const { pathname } = location;

    return(
        <div 
            className='animation'
            style={{ 
                gridColumnStart: props.size.x<150 ? '2' : '3', 
                gridColumnEnd: props.size.x<150 ? '4' : `5`,
                gridTemplateRows: visibilityToggleForCustomInputs ? '2.4rem 1fr auto auto' : '2.4rem 1fr auto 4.2rem'
            }}
        >
            <div className='animation-header'>
                {
                    pathname === '/minimum-path-sum' ?
                    <button className='flex-item status-info-button' onClick={() => dispatch(changeStatusInfoState())}>
                        <span>Status Info&nbsp;</span>
                        <div style={{ transform: !statusInfoState && 'rotate(180deg)' }}>
                            <img src={ArrowSvg} />
                        </div>
                    </button>:
                    <div className='flex-item status-info-button'></div>
                }
                <div className='animation-header-control-buttons flex-item'>
                    <div className='playback-speed'>
                        <img src={PlaybackSpeedSvg} />
                    </div>
                    <div className='restart-button'>
                        <div onClick={() => dispatch(toggleRestart())}>
                            <img src={ResetAnimationSvg} />
                        </div>
                    </div>
                    <div className='fullscreen-toggle'>
                        <div onClick={() => props.handleFullScreen()}>
                            {
                                props.isFullscreen ?
                                <img src={FullscreenSvg} />:
                                <img src={FullscreenExitSvg} />
                            }
                        </div>
                    </div>
                    
                </div>
            </div>
            <AnimationFoundation />
            <ProgressBar />
            <AnimationControlWindow />
        </div>
    )
}