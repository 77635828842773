import One from '../images/1.png';
import Two from '../images/2.png';
import Three from '../images/3.png';
import Four from '../images/4.png';
import Five from '../images/5.png';
import Six from '../images/6.png';
import Seven from '../images/7.png';
import Eight from '../images/8.png';
import Nine from '../images/9.png';

export const SecondSection = () => {

    return (
        <div className='windows-container'>
            <div className='windows-row'>
                <div className="window">
                    <img src={One} alt={"1png"} />
                </div>
                <div className="window">
                    <img src={Two} alt={"2png"} />
                </div>
                <div className="window">
                    <img src={Three} alt={"3png"} />
                </div>
            </div>
            <div className='windows-row'>
            <div className="window">
                    <img src={Four} alt={"4png"} />
                </div>
                <div className="window">
                    <img src={Five} alt={"5png"} />
                </div>
                <div className="window">
                    <img src={Six} alt={"6png"} />
                </div>
            </div>
            <div className='windows-row'>
            <div className="window">
                    <img src={Seven} alt={"7png"} />
                </div>
                <div className="window">
                    <img src={Eight} alt={"8png"} />
                </div>
                <div className="window">
                    <img src={Nine} alt={"9png"} />
                </div>
            </div>
        </div>
    )
}