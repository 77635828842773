import { backgroundColorChange, backgroundColorChangeFlow, backgroundColorChangeReverseFlow } from '../backgroundAnimation/backgroundColorChange';
import { borderColorChange } from '../borderAnimation/borderColorChange';
import { changeAlgorithmOutputText } from '../changeTextAnimation/changeAlgorithmOutputText';
import { ERROR, LIGHT_PURPLE, MINIMUM,SUCCESS } from '../colorScheme';
import TreeNode from '../tree/TreeNode';
import { traverseTreeRefsUtil } from '../tree/traverseTreeRefsUtil';

const findLCA = (node,p,q,timeline,refLines,algorithmOutputRef) => {

    const childNodes = node.value.childNodes;
    
    backgroundColorChange(timeline,refLines[1],[MINIMUM],1000,'+=0')
    backgroundColorChange(timeline,childNodes[childNodes.length-1],[MINIMUM],1000,'-=1000')
    borderColorChange(timeline,childNodes[childNodes.length-1],[MINIMUM],1000,'-=1000')
    if (node===null) {
        backgroundColorChange(timeline,refLines[1],[SUCCESS],1000,'+=0')
        backgroundColorChange(timeline,refLines[2],[MINIMUM],1000,'+=0')
        return null;
    } else {
        backgroundColorChange(timeline,refLines[1],[ERROR,LIGHT_PURPLE],1000,'+=0')
    }
    backgroundColorChange(timeline,refLines[1],[LIGHT_PURPLE],1000,'+=0')

    backgroundColorChange(timeline,refLines[3],[MINIMUM],1000,'+=0')
    if (parseInt(node.value.innerText) > p && parseInt(node.value.innerText) > q) {
        backgroundColorChange(timeline,refLines[3],[SUCCESS,LIGHT_PURPLE],1000,'+=0')
        backgroundColorChange(timeline,refLines[4],[MINIMUM,LIGHT_PURPLE],1000,'+=0')
        backgroundColorChange(timeline,refLines[0],[MINIMUM,LIGHT_PURPLE],1000,'+=0')
        backgroundColorChangeFlow(timeline,childNodes[1],MINIMUM,childNodes[0].style.width,1000,'-=1000')
        return findLCA(node.left,p,q,timeline,refLines,algorithmOutputRef);
    } else {
        backgroundColorChange(timeline,refLines[3],[ERROR,LIGHT_PURPLE],1000,'+=0')
    }
    
    backgroundColorChange(timeline,refLines[5],[MINIMUM],1000,'+=0')
    if (parseInt(node.value.innerText) < p && parseInt(node.value.innerText) < q) {
        backgroundColorChange(timeline,refLines[5],[SUCCESS,LIGHT_PURPLE],1000,'+=0')
        backgroundColorChange(timeline,refLines[6],[MINIMUM,LIGHT_PURPLE],1000,'+=0')
        backgroundColorChange(timeline,refLines[0],[MINIMUM,LIGHT_PURPLE],1000,'+=0')
        backgroundColorChangeFlow(timeline,childNodes[childNodes.length-2],MINIMUM,childNodes[childNodes.length-3].style.width,1000,'-=1000')
        return findLCA(node.right,p,q,timeline,refLines,algorithmOutputRef);
    } else {
        backgroundColorChange(timeline,refLines[5],[ERROR,LIGHT_PURPLE],1000,'+=0')
    }
    
    backgroundColorChange(timeline,refLines[7],[MINIMUM,LIGHT_PURPLE],1000,'+=0')
    backgroundColorChange(timeline,childNodes[childNodes.length-1],[SUCCESS],1000,'+=0')
    borderColorChange(timeline,childNodes[childNodes.length-1],[SUCCESS],1000,'-=1000')
    backgroundColorChange(timeline,refLines[8],[SUCCESS],1000,'+=0')
    changeAlgorithmOutputText(timeline,algorithmOutputRef.current,childNodes[childNodes.length-1].innerHTML)
    return node;
}

export const leastCommonAncestor = (refs,timeline,refLines,algorithmOutputRef) => {

    const {containerRef, nodes} = {...refs}

    const listOfNodes = containerRef.current.childNodes;

    if(listOfNodes.length===0)
        return
    
    let root = new TreeNode(listOfNodes[0]);
    traverseTreeRefsUtil(root,listOfNodes)
    
    backgroundColorChange(timeline,refLines[0],[MINIMUM,LIGHT_PURPLE],1000,'+=0')
    findLCA(root,nodes.p,nodes.q,timeline,refLines,algorithmOutputRef)

}