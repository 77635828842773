import { useDispatch, useSelector } from "react-redux"
import { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { setCustomInput } from "../../../actions/setCustomInput";
import { defaultCustomInput } from "../../../reducers/customInput";
import './CustomInputWindow.css'

export const CustomInputWindow = () => {

    const dispatch = useDispatch();
    const [input, setInput] = useState({
        firstInput: '',
        secondInput: '',
        thirdInput: ''
    });
    const customInput = useSelector(state => state.customInput)
    const visibilityToggleForCustomInputs = useSelector(state => state.visibilityToggleForCustomInputs)

    const location = useLocation();
    const { pathname } = location;

    const handleInputChange = (e) => {
        if(e.target.id==='first-input')
            setInput(prevState => ({
                    ...prevState, 
                    firstInput: e.target.value
            }))
        else if(e.target.id==='second-input')
            setInput(prevState => ({
                ...prevState, 
                secondInput: e.target.value
            }))
        else if(e.target.id==='third-input')
            setInput(prevState => ({
                ...prevState, 
                thirdInput: e.target.value
            }))
    }    

    const handleApply = () => {
        dispatch(setCustomInput(JSON.stringify(input)))
    }

    const handleResetToDefault = () => {
        setInput(defaultCustomInput)
        dispatch(setCustomInput(defaultCustomInput))
    }

    useEffect(() => {
        if(customInput!=='')
            setInput(JSON.parse(customInput))
    },[customInput])

    return(
        <>
            {
                visibilityToggleForCustomInputs &&
                <div className='custom-input-container'>
                    <div className='input-array'>BST (as a list)=</div>
                    <input 
                        id='first-input'
                        type='text' 
                        onChange={(e) => handleInputChange(e,'first-input')}
                        value={input['firstInput']}
                        className='input-array-field'
                    />
                    {
                        pathname === '/lowest-common-ancestor' && 
                        <>
                            <div className='input-array'>p=</div>
                            <input 
                                id='second-input'
                                type='text' 
                                onChange={(e) => handleInputChange(e,'second-input')}
                                value={input['secondInput']}
                                className='input-array-field'
                            />
                            <div className='input-array'>q=</div>
                            <input 
                                id='third-input'
                                type='text' 
                                onChange={(e) => handleInputChange(e,'third-input')}
                                value={input['thirdInput']}
                                className='input-array-field'
                            />
                        </>
                    }
                    <div className='custom-input-action-buttons'>
                        <button 
                            className='reset-to-default' 
                            onClick={handleResetToDefault}
                        >
                            Reset to default
                        </button>
                        <button 
                            className='apply' 
                            onClick={handleApply}
                        >Apply
                        </button>
                    </div>
                </div>
            }
        </>
    )
}