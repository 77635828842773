import './HomePage.css'
import { FeaturesSection } from './SubComponents/FeaturesSection/FeaturesSection';
import { HeaderHomePage } from '../../components/Header/HeaderHomePage'
import { BannerSection } from './SubComponents/Bannersection';
import 'bootstrap/dist/css/bootstrap.min.css';
import { HeroSection } from './SubComponents/HeroSection';
import { FooterSection } from './SubComponents/FooterSection';
import { MenuItemsForMobile } from '../../components/Header/SubComponents/MenuItemsForMobile';
import { useSelector } from 'react-redux';

export const HomePage = () => {

    const mobileMenuState = useSelector(state => state.mobileMenuState)

    return(
        <div style={{ overflowX: 'hidden'}}>
            <HeaderHomePage />
            {
                !mobileMenuState ? 
                <>
                    <HeroSection />
                    <BannerSection />
                    <FeaturesSection />
                    <FooterSection />
                </> :
                <MenuItemsForMobile />
            }
        </div>
    )
}