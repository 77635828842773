import React, {useState,useEffect,createRef} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HomePage } from '../../pages/HomePage/HomePage';
import { Header } from '../Header/Header';
import AnimationContext from '../../context/AnimationContext';
import AlgorithmContext from '../../context/AlgorithmContext';
import anime from "animejs/lib/anime.es.js"
import { useSelector } from 'react-redux'
import { ListOfAlgorithms } from '../../pages/ListOfAlgorithms/ListOfAlgorithms';
import { AnimationsPage } from '../../pages/AnimationsPage/AnimationPage';
import AlgorithmOutputContext from '../../context/AlgorithmOutputContext';
import { AboutUsPage } from '../../pages/AboutUsPage/AboutUsPage';
import { ListOfBlogs } from '../../pages/BlogsPage/ListOfBlogs';

const App = () => {

  const customInput = useSelector(state => state.customInput)
  const algorithmComponentVisibility = useSelector(state => state.algorithmComponentVisibility)

  const [timeline, setTimeline] = useState(null);
  let refLines = new Array().fill(undefined);
  const algorithmOutputRef = createRef();
  
  for(let i=0;i<10;i++){
    refLines[i] = createRef();
  }

  useEffect(() => {
    const newTimeline = anime.timeline({
      easing: 'cubicBezier(0.4, 0, 0.2, 1)',
      autoplay: false,
    });
    
    if(timeline){
      timeline.pause();
    }

    setTimeline(newTimeline);
  }, [customInput,algorithmComponentVisibility]);

  return (
    <Router>
      <AlgorithmOutputContext.Provider value={algorithmOutputRef}>
        <AlgorithmContext.Provider value={refLines}>
          <AnimationContext.Provider value={timeline}>
              <Routes>
                <Route path="/" exact element={<HomePage />} />
                <Route path="/selection-sort" element={<AnimationsPage />} />
                <Route path="/bubble-sort" element={<AnimationsPage />} />
                <Route path="/insertion-sort" element={<AnimationsPage />} />
                <Route path='/algorithms' element={<ListOfAlgorithms />} />
                <Route path='/minimum-path-sum' element={<AnimationsPage />} />
                <Route path="/minimum-node" element={<AnimationsPage />} />
                <Route path="/in-order-traversal" element={<AnimationsPage />} />
                <Route path="/pre-order-traversal" element={<AnimationsPage />} />
                <Route path="/post-order-traversal" element={<AnimationsPage />} />
                <Route path="/lowest-common-ancestor" element={<AnimationsPage />} />
                <Route path="/about-us" element={<AboutUsPage />} />
                <Route path="/blogs" element={<ListOfBlogs />} />
              </Routes>
          </AnimationContext.Provider>
        </AlgorithmContext.Provider>
      </AlgorithmOutputContext.Provider>
    </Router>
  )
}

export default App;