import { useContext } from "react"
import AlgorithmContext from "../../../../context/AlgorithmContext";

export const MinimumPathSumAlgorithmContent = () => {

    const refLines = useContext(AlgorithmContext);

    return (
        <div>
            <h2 className='description-title'>Minimum Path Sum</h2>
            <div className='description-content-inside description-content-box code'>
                <div className='mps-function-line'>function <span className='primary-purple'>minPathSum(grid)</span>:</div>
                <div className='indent-1'>m = number of rows in grid</div>
                <div className='indent-1'>n = number of columns in grid</div>
                <br/>
                <div className='indent-1'>Create a DP table dp with dimensions m x n</div>
                <br/>
                <div className='indent-1'><span ref={refLines[0]}>Initialize dp<span className='primary-pink'>[0][0]</span> with grid<span className='primary-pink'>[0][0]</span></span></div>
                <br/>
                <div className='comment-color indent-1'>// Initialize the first column</div>
                <div className='mps-inner-line-2 indent-1'><span ref={refLines[1]}>for j from 1 to m-1:</span></div>
                <div className='indent-2'><span ref={refLines[2]}>dp<span className='primary-pink'>[j][0]</span> = dp<span className='primary-pink'>[j-1][0]</span> + grid<span className='primary-pink'>[j][0]</span></span></div>
                <br/>
                <div className='comment-color indent-1'>// Initialize the first row</div>
                <div className='mps-inner-line-1 indent-1'><span ref={refLines[3]}>for i from 1 to n-1:</span></div>
                <div className='indent-2'><span ref={refLines[4]}>dp<span className='primary-pink'>[0][i]</span> = dp<span className='primary-pink'>[0][i-1]</span> + grid<span className='primary-pink'>[0][i]</span></span></div>
                <br/>
                <div className='comment-color indent-1'>// Fill in the DP table</div>
                <div className='mps-inner-line-3 indent-1'><span ref={refLines[5]}>for i from 1 to m-1:</span></div>
                <div className='indent-2'><span ref={refLines[6]}>for j from 1 to n-1:</span></div>
                <div className='indent-3'><span ref={refLines[7]}>dp<span className='primary-pink'>[i][j]</span> = min(dp[i-1][j], dp[i][j-1]) + grid<span className='primary-pink'>[i][j]</span></span></div>
                <br/>
                <div className='comment-color indent-1'>// Return the bottom-right cell of dp, which contains the minimum path sum</div>
                <div className='indent-1'><span ref={refLines[8]}>return dp<span className='primary-pink'>[m-1][n-1]</span></span></div>
            </div>
        </div>
    )
}