import { useContext } from "react";
import AlgorithmContext from "../../../../../context/AlgorithmContext";
import AlgorithmOutputContext from "../../../../../context/AlgorithmOutputContext";

export const LowestCommonAncestorAlgorithmContent = () => {
    
    const refLines = useContext(AlgorithmContext);
    const algorithmOutputRef = useContext(AlgorithmOutputContext)

    return(
        <div>
            <h2 className='description-title'>Lowest Common Ancestor</h2>
            <h3>Pseudo Code</h3>
            <div className='description-content-inside description-content-box code'>
                <div className='lca-procedure-line'><span ref={refLines[0]}>procedure <span className='primary-purple'>lowestCommonAncestor</span>(root, p, q):</span></div>
                <div className='indent-1 lca-first-if-loop'><span ref={refLines[1]}>if root is <span className='primary-pink'>null</span>:</span></div>
                <div className='indent-2'><span ref={refLines[2]}>return null</span></div>
                <div className='indent-1 lca-second-if-loop'><span ref={refLines[3]}>if p.val <span className='primary-pink'>&lt;</span> root.val <span className='primary-pink'>and</span> q.val <span className='primary-pink'>&lt;</span> root.val:</span></div>
                <div className='indent-2'><span ref={refLines[4]}>return <span className='primary-purple'>lowestCommonAncestor</span>(root.left, p, q)</span></div>
                <div className='indent-1 lca-else-if-loop'><span ref={refLines[5]}>else if p.val <span className='primary-pink'>&gt;</span> root.val <span className='primary-pink'>and</span> q.val <span className='primary-pink'>&gt;</span> root.val:</span></div>
                <div className='indent-2'><span ref={refLines[6]}>return <span className='primary-purple'>lowestCommonAncestor</span>(root.right, p, q)</span></div>
                <div className='indent-1 lca-else-loop'><span ref={refLines[7]}>else:</span></div>
                <div className='indent-2'><span ref={refLines[8]}>return root</span></div>
                <div>end procedure</div>
            </div>
            <h3>Output</h3>
            <input 
                type='text' 
                value=''
                ref={algorithmOutputRef}
                className='output-array-field'
                readOnly
            />
        </div>
    )
}