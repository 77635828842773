export const BubbleSortAnimationContent = () => (
    <div>
        <h4 className='description-title'>Bubble Sort</h4>
        <div className='description-content-inside description-content-box code'>
            <div className='bubble-procedure-line'>procedure bubbleSort(arr: list of comparable elements)</div>
            <div className='indent-1'><span className='success-box'>n = length(arr)</span></div>
            <div className='bubble-repeat indent-1'>repeat</div>
            <div className='indent-2'>swapped = false</div>
            <div className='bubble-for-loop indent-2'><span className='error-box'>for i = <span className='primary-pink'>1</span> to n - <span className='primary-pink'>1</span> do</span></div>
            <div className='bubble-if-loop indent-3'>if arr[i-<span className='primary-pink'>1</span>] &gt; arr[i] then</div>
            <div className='indent-4'><span className='in-progress-box'>swap(arr[i-<span className='primary-pink'>1</span>], arr[i])</span></div>
            <div className='indent-4'>swapped = true</div>
            <div className='indent-3'>end if</div>
            <div className='indent-2'>end for</div>
            <div className='indent-2'>n = n - 1  // Last element is now in its correct place</div>
            <div className='indent-1'>until not swapped</div>
            <div>end procedure</div>
        </div>
    </div>
)