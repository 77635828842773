import { animationTypeTwo } from "../animationTypeTwo";

export const swapAnimation = (animationLoop,target1,target2,distX,width,height,timeFactor) => {
    
    // box1 - moveY+1 -> moveX+4 -> moveY-1
    animationLoop.add(animationTypeTwo(target1,distX,width,1,height,timeFactor))

    // box5 - moveY+1 -> moveX-4 -> moveY-1
    animationLoop.add(animationTypeTwo(target2,-distX,width,1,height,timeFactor),`-=${3*timeFactor}`)

    return animationLoop;
}