import { Link } from 'react-router-dom';

export const BlogCard = (props) => {
    return(
        <div className='container card my-4' style={{ backgroundColor: '#F1F2F9', border: 'none', color: '#4B5399' }}>
            <div className='row p-4'>
                {console.log('props',props)}
                <img src={props.image} className='col-md-6'/>
                <div className='col-md-6 p-4'>
                    <h3>{props.title}</h3>
                    {
                        props.tags.map( tag => (
                            <button className='tags'>{tag}</button>
                        ))
                    }
                    <Link to={props.blogLink}>
                        <button className='play-animation-button d-flex text-nowrap'>
                            Read Blog
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    )
}