import { Link } from 'react-router-dom';

export const GeekosophersTitleAndMenuItems = () => {
    return(
      <div className='flex-item flex-item2'>
        <h2>
          <Link to="/" className='flex-align-center sophers-color hide-display-in-mobile'>
            Geekosophers
          </Link>
        </h2>
        <div className='menu-items-flex-display hide-display-in-mobile'>
          <Link to="/about-us" className='flex-align-center sophers-color'>About Us</Link>
          <Link to="/algorithms" className='flex-align-center sophers-color'>Animations</Link>
          <Link to="/" className='flex-align-center sophers-color'>Slide Savvy</Link>
          <Link to="/blogs" className='flex-align-center sophers-color'>Blogs</Link>
        </div>
      </div>
    )
}