import { FirstSection } from './FirstSection';
import { SecondSection } from './SecondSection';
import { SecondSectionForMobile } from './SecondSectionForMobile';

export const HeroSection = () => (
    <>
        <div className="container height-fit-content-sm">
            <div className="row">
                <div className="col-md-4 show-padding-sm">
                    <FirstSection />
                </div>
                <div className="col-sm-8 hide-display-in-mobile">
                    <SecondSection />
                </div>
            </div>
        </div>
        <SecondSectionForMobile />
    </>
)