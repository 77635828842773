export const BubbleSortDescriptionContent = () => (
    <div>
        <h4 className='description-title'>Bubble Sort</h4>
        <div className='description-content-inside'>Bubble Sort is a simple sorting algorithm that repeatedly steps through the list, compares adjacent elements, and swaps them if they are in the wrong order. The pass through the list is repeated until no swaps are needed, which means the list is sorted. Bubble Sort has a time complexity of <span className='primary-purple'>O(n^2)</span> in its worst and average cases, making it inefficient for large lists, but it is easy to understand and implement.</div>
        <h4>Example-1</h4>
        <div className='description-content-inside description-content-box'>
            <div>Input: <span className='primary-purple'>nums = [ 0, 1, 0, 3, 12 ]</span></div>
            <div>Output: <span className='primary-purple'>[ 0, 0, 1, 3, 12 ]</span></div>
        </div>
        <h4>Example-2</h4>
        <div className='description-content-inside description-content-box'>
            
            <div>Input: <span className='primary-purple'>nums = [23, 40, 11, 1]</span></div>
            <div>Output: <span className='primary-purple'>[1, 11, 23, 40]</span></div>
        </div>
        <h4>JAVA Solution -</h4>
        <div className='description-content-inside description-content-box code'>
            <div>public class BubbleSort &#123;</div>
            <div className='indent-1'>public static void main(String[] args) &#123;</div>
            <div className='indent-2'>int[] arr = &#123;64, 34, 25, 12, 22, 11, 90&#125;;</div>
            <div className='indent-2'>int n = arr.length;</div>
            <div></div>
            <div className='indent-2'>bubbleSort(arr);</div>
            <div></div>
            <div className='indent-2'>System.out.println("Sorted array:");</div>
            <div className='indent-2'>for (int i = 0; i &lt; n; i++) &#123;</div>
            <div className='indent-3'>System.out.print(arr[i] + " ");</div>
            <div className='indent-2'>&#125;</div>
            <div className='indent-1'>&#125;</div>
            <div></div>
            <div className='indent-1'>public static void bubbleSort(int[] arr) &#123;</div>
            <div className='indent-2'>int n = arr.length;</div>
            <div className='indent-2'>boolean swapped;</div>
            <div></div>
            <div className='indent-2'>for (int i = 0; i &lt; n - 1; i++) &#123;</div>
            <div className='indent-3'>swapped = false;</div>
            <div className='indent-3'>for (int j = 0; j &lt; n - i - 1; j++) &#123;</div>
            <div className='indent-4'>if (arr[j] &gt; arr[j + 1]) &#123;</div>
            <div className='indent-5'>// Swap arr[j] and arr[j + 1]</div>
            <div className='indent-5'>int temp = arr[j];</div>
            <div className='indent-5'>arr[j] = arr[j + 1];</div>
            <div className='indent-5'>arr[j + 1] = temp;</div>
            <div className='indent-5'>swapped = true;</div>
            <div className='indent-4'>&#125;</div>
            <div className='indent-3'>&#125;</div>
            <div></div>
            <div className='indent-3'>// If no two elements were swapped in inner loop, the array is already sorted</div>
            <div className='indent-3'>if (!swapped) &#123;</div>
            <div className='indent-4'>break;</div>
            <div className='indent-3'>&#125;</div>
            <div className='indent-2'>&#125;</div>
            <div className='indent-1'></div>
            <div>&#125;</div>
        </div>
    </div>
)