import './DescriptionWidthHandler.css'

export const DescriptionWidthHandler = (props) => {

    const handleDescriptionWindowWidth = (mouseDownEvent) => {
        const startSize = props.size;
        const startPosition = { x: mouseDownEvent.pageX };
        
        const onMouseMove = (mouseMoveEvent) => {
          if(mouseMoveEvent.pageX<150){
            props.setSize(() => ({ 
              x: 0
            }));
          }
          else if((mouseMoveEvent.pageX>150 && mouseMoveEvent.pageX<300) || mouseMoveEvent.pageX>window.innerWidth-500) return;
          else{
            props.setSize(() => ({ 
              x: startSize.x - startPosition.x + mouseMoveEvent.pageX
            }));
          }
        }
        const onMouseUp = () => {
          document.body.removeEventListener("mousemove", onMouseMove);
        }
        
        document.body.addEventListener("mousemove", onMouseMove);
        document.body.addEventListener("mouseup", onMouseUp, { once: true });
    };

    const setDecriptionWithTo300 = () => {
        props.setSize(size => (
            {
            x: size.x === 0 ? 300 : size.x
            }
        ))
    }

    const handleTouchMove = (touchMoveEvent) => {
      const touchX = touchMoveEvent.touches[0].pageX;
      const windowWidth = window.innerWidth;
      
      if (touchX < 150) {
        props.setSize({ x: 0 });
      } else if ((touchX > 150 && touchX < 300) || touchX > windowWidth - 500) {
        return;
      } else {
        props.setSize({ x: props.startSize.x - props.startPosition.x + touchX });
      }
    };

    return(
        <div 
            type='button' 
            onMouseDown={handleDescriptionWindowWidth}
            onClick={setDecriptionWithTo300}
            className='description-window-handler'
            onTouchMove={handleTouchMove}
        >
          <div style={{ width: 'auto', paddingLeft: '2px' }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 34.64">
              <g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1">
                <path d="M4,15.32a2,2,0,1,1-2-2A2,2,0,0,1,4,15.32ZM4,8.66a2,2,0,1,1-2-2A2,2,0,0,1,4,8.66ZM4,2A2,2,0,1,1,2,0,2,2,0,0,1,4,2Z"/>
                </g>
                </g>
            </svg>
          </div>
        </div>
    )
}