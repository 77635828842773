export const MinimumPathSumDescriptionContent = () => (
    <div>
        <h2 className='description-title'>Minimum Path Sum</h2>
        <div className='description-content-inside'>Given a grid of non-negative integers representing the values of each cell, you need to find the minimum sum path from the top-left corner to the bottom-right corner. You can only move down or right at each step.</div>
        <h3>Example-1</h3>
        <div className='description-content-inside description-content-box'>
            <div>Input: <span className='primary-purple'>arr = [[1, 3, 1], [1, 5, 1], [4, 2, 1]]</span></div>
            <div>Output: <span className='primary-purple'>7</span></div>
        </div>
        <h3>Example-2</h3>
        <div className='description-content-inside description-content-box'>
            
            <div>Input: <span className='primary-purple'>arr = [[1, 2, 3], [4, 5, 6]]</span></div>
            <div>Output: <span className='primary-purple'>12</span></div>
        </div>

        <h3>JAVA Solution</h3>
        <div className='description-content-inside description-content-box code'>
            <div>public int minPathSum(int[][] grid) &#123;</div>
            <div className='indent-1'>int m = grid.length; <span className='comment-color'>// Number of rows</span></div>
            <div className='indent-1'>int n = grid[0].length; <span className='comment-color'>// Number of columns</span></div>
            <div className='comment-color indent-1'>// Create a DP table to store the minimum sum for each cell</div>
            <div className='indent-1'>int[][] dp = new int[m][n];</div>
            <div className='comment-color indent-1'>// Initialize the top-left cell with its value</div>
            <div className='indent-1'>dp[0][0] = grid[0][0];</div>
            <div className='comment-color indent-1'>// Initialize the first row: You can only move right</div>
            <div className='indent-1'>for (int i = 1; i &lt; n; i++) &#123;</div>
            <div className='indent-2'>dp[0][i] = dp[0][i - 1] + grid[0][i];</div>
            <div className='indent-1'>&#125;</div>
            <div className='comment-color indent-1'>// Initialize the first column: You can only move down</div>
            <div className='indent-1'>for (int j = 1; j &lt; m; j++) &#123;</div>
            <div className='indent-2'>dp[j][0] = dp[j - 1][0] + grid[j][0];</div>
            <div className='indent-1'>&#125;</div>
            <div className='comment-color indent-1'>// Fill in the DP table</div>
            <div className='indent-1'>for (int i = 1; i &lt; m; i++) &#123;</div>
            <div className='indent-2'>for (int j = 1; j &lt;n; j++) &#123;</div>
            <div className='indent-3'>// Choose the minimum of moving down or moving right</div>
            <div className='indent-3'>dp[i][j] = Math.min(dp[i - 1][j], dp[i][j - 1]) + grid[i][j];</div>
            <div className='indent-2'>&#125;</div>
            <div className='indent-1'>&#125;</div>
            <div className='comment-color indent-1'>// The bottom-right cell contains the minimum path sum</div>
            <div className='indent-1'>return dp[m - 1][n - 1];</div>
            <div>&#125;</div>
        </div>
    </div>
)