import userIcon from './user-icon.svg';
import { useLocation } from 'react-router-dom';

export const UserIcon = () => {

  const location = useLocation();
 
  const isLocationApp = () => {
    return location.pathname === '/' || 
      location.pathname === '/algorithms' ||
      location.pathname === '/about-us' ||
      location.pathname === '/blogs';
  }
  
    return (
        <div className={ isLocationApp() ? 'flex-item flex-item3' : 'flex-item flex-item3-app' }>
          <img src={userIcon} />
        </div>
    )
}