import { useSelector } from 'react-redux';
import { CustomInputWindow } from '../CustomInputWindow/CustomInputWindow';
import './AnimationControlWindow.css'
import { AnimationControlsContainer } from './AnimationControlsContainer';
import { CustomInputBorder } from './CustomInputBorder';

export const AnimationControlWindow = () => {

    const visibilityToggleForCustomInputs = useSelector(state => state.visibilityToggleForCustomInputs)

    return(
        <div className='animation-controls-wrapper' style={{ gridTemplateRows: !visibilityToggleForCustomInputs ? '4.2rem' : '4.2rem 0.1rem 1fr' }}>
            <AnimationControlsContainer />
            <CustomInputBorder />
            <CustomInputWindow />
        </div>
    )
}