export const changeAlgorithmOutputText = (timeline,target,newText) => {
    timeline.add({
        targets: target,
        duration: 100,
        update: function(anim) {
            var progress = anim.progress;
            if (progress < 50) {
                var array = target.value.split(","); 
                if(array.pop()==newText){ 
                    var outputString = array.join(",");
                    target.value = outputString;
                }
            } else {
                let oldValue = target.value
                if(oldValue==='')
                    target.value = newText;
                else{
                    var array = oldValue.split(","); 
                    if(!array.includes(newText)){
                        target.value = `${oldValue},${newText}`;
                    }
                }
            }
        }
    })
}