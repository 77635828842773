import { useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setAnimationProgress } from "../actions/setAnimationProgress";
import { changeAnimationState } from "../actions/changeAnimationState";
import { setPauseStep } from "../actions/setStep";
import AnimationContext from "../context/AnimationContext";
import { seekActiveChild } from "../util/seekActiveChild";

export const usePlayPauseAnimation = () => {

    const animationState = useSelector(state => state.animationState)
    const restartAnimation = useSelector(state => state.restartAnimation)
    const dispatch = useDispatch();
    const timeline = useContext(AnimationContext);

    const handlePause = () => {
        
        if (timeline) {
            const activeChildIndex = seekActiveChild(timeline)
            dispatch(setPauseStep(activeChildIndex))

            timeline.pause();
        }
    };
    
    const handlePlay = () => {
        if (timeline) {
          timeline.play();
        }
    };

    const handleRestart = () => {
        if (timeline) {
          timeline.restart();
          handlePause();
          dispatch(setAnimationProgress(0));
          animationState && dispatch(changeAnimationState());
        }
    };

    useEffect(() => {
        if(animationState){
            handlePlay()
        } else{
            handlePause()
        }
    },[animationState,timeline])

    useEffect(() => {
        handleRestart()
    },[restartAnimation])

}