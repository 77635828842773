import { useRef } from 'react';
import './AnimationFoundation.css';
import { useLocation } from 'react-router-dom';
import { SelectionSortAnimation } from '../../pages/AnimationsPage/SortingAlgorithms/SelectionSortPage/SelectionSortAnimation/SelectionSortAnimation';
import { InsertionSortAnimation } from '../../pages/AnimationsPage/SortingAlgorithms/InsertionSortPage/InsertionSortAnimation/InsertionSortAnimation';
import { BubbleSortAnimation } from '../../pages/AnimationsPage/SortingAlgorithms/BubbleSortPage/BubbleSortAnimation/BubbleSortAnimation';
import { MinimumPathSumAnimation } from '../../pages/AnimationsPage/MinimumPathSum/MinimumPathSumAnimation/MinimumPathSumAnimation';
import { MinimumNodeAnimation } from '../../pages/AnimationsPage/TreeDataStructure/MinimumNode/MinimumNodeAnimation/MinimumNodeAnimation';
import { InOrderAnimation } from '../../pages/AnimationsPage/TreeDataStructure/TreeTraversal/InOrder/InOrderAnimation/InOrderAnimation';
import { PreOrderAnimation } from '../../pages/AnimationsPage/TreeDataStructure/TreeTraversal/PreOrder/PreOrderAnimation/PreOrderAnimation';
import { PostOrderAnimation } from '../../pages/AnimationsPage/TreeDataStructure/TreeTraversal/PostOrder/PostOrderAnimation/PostOrderAnimation';
import { LowestCommonAncestorAnimation } from '../../pages/AnimationsPage/TreeDataStructure/LowestCommonAncestor/LowestCommonAncestorAnimation/LowestCommonAncestorAnimation';

export const AnimationFoundation = () => {
    
    const ref = useRef(null);
    const location = useLocation();
    const { pathname } = location;

    return(
        <div className='animation-container' ref={ref}>
            
            {pathname === '/selection-sort' && <SelectionSortAnimation />}
            {(pathname === '/bubble-sort' || pathname === '/') && <BubbleSortAnimation />}
            {pathname === '/insertion-sort' && <InsertionSortAnimation />}
            {pathname === '/minimum-path-sum' && <MinimumPathSumAnimation />}
            {pathname === '/minimum-node' && <MinimumNodeAnimation />}
            {pathname === '/in-order-traversal' && <InOrderAnimation />}
            {pathname === '/pre-order-traversal' && <PreOrderAnimation />}
            {pathname === '/post-order-traversal' && <PostOrderAnimation />}
            {pathname === '/lowest-common-ancestor' && <LowestCommonAncestorAnimation />}
        </div>
    )
}