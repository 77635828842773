import React from "react"

export const SelectionSortDescriptionContent = () => (
    <div>
        <h2 className='description-title'>Selection Sort</h2>
        <div className='description-content-inside'>Selection Sort is a simple sorting algorithm that works by repeatedly selecting the minimum (or maximum) element from the unsorted part of the array and putting it at the beginning (or end) of the sorted part. The algorithm maintains two subarrays within the given array: the sorted subarray and the unsorted subarray. In each iteration, the minimum element from the unsorted subarray is selected and swapped with the leftmost element of the unsorted subarray.</div>
        <h3>Example-1</h3>
        <div className='description-content-inside description-content-box'>
            <div>Input: <span className='primary-purple'>nums = [ 0, 1, 0, 3, 12 ]</span></div>
            <div>Output: <span className='primary-purple'>[ 0, 0, 1, 3, 12 ]</span></div>
        </div>
        <h3>Example-2</h3>
        <div className='description-content-inside description-content-box'>
            
            <div>Input: <span className='primary-purple'>nums = [23, 40, 11, 1]</span></div>
            <div>Output: <span className='primary-purple'>[1, 11, 23, 40]</span></div>
        </div>
        <h3>JAVA Solution -</h3>
        <div className='description-content-inside description-content-box code'>
            <div>public class SelectionSort &#123;</div>
            <div className='indent-1'>public static void selectionSort(int[] arr) &#123;</div>
            <div className='indent-2'>int n = arr.length;</div>
            <div className='indent-2'>for (int i = 0; i &lt; n - 1; i++) &#123;</div>
            <div className='indent-3'>int minIndex = i;</div>
            <div className='indent-3'>// Find the index of the minimum element in the unsorted subarray</div>
            <div className='indent-3'>for (int j = i + 1; j &lt; n; j++) &#123;</div>
            <div className='indent-4'>if (arr[j] &lt; arr[minIndex]) &#123;</div>
            <div className='indent-5'>minIndex = j;</div>
            <div className='indent-4'>&#125;</div>
            <div className='indent-3'>&#125;</div>
            <div className='indent-3'>// Swap the minimum element with the first element of the unsorted subarray</div>
            <div className='indent-3'>int temp = arr[minIndex];</div>
            <div className='indent-3'>arr[minIndex] = arr[i];</div>
            <div className='indent-3'>arr[i] = temp;</div>
            <div className='indent-2'>&#125;</div>
            <div className='indent-1'>&#125;</div>
            <div className='indent-1'>public static void main(String[] args) &#123;</div>
            <div className='indent-2'>int[] arr = &#123;64, 25, 12, 22, 11&#123;;</div>
            <div className='indent-2'>selectionSort(arr);</div>
            <div className='indent-2'>System.out.println("Sorted array: " + Arrays.toString(arr));</div>
            <div className='indent-1'>&#125;</div>
            <div>&#125;</div>
        </div>
    </div>
)