export const InsertionSortDescriptionContent = () => (
    <div>
        <h2 className='description-title'>Insertion Sort</h2>
        <div className='description-content-inside'>Insertion Sort is a simple sorting algorithm that builds the final sorted array one item at a time. It iterates through the list and removes one element at a time, placing it in its correct position within the sorted portion of the array. It repeats this process until the entire list is sorted. Insertion Sort is efficient for small data sets and is often used in practice for small inputs. It has a time complexity of <span className='primary-purple'>O(n^2)</span> in its worst and average cases.</div>
        <h3>Example-1</h3>
        <div className='description-content-inside description-content-box'>
            <div>Input: <span className='primary-purple'>nums = [ 0, 1, 0, 3, 12 ]</span></div>
            <div>Output: <span className='primary-purple'>[ 0, 0, 1, 3, 12 ]</span></div>
        </div>
        <h3>Example-2</h3>
        <div className='description-content-inside description-content-box'>
            
            <div>Input: <span className='primary-purple'>nums = [23, 40, 11, 1]</span></div>
            <div>Output: <span className='primary-purple'>[1, 11, 23, 40]</span></div>
        </div>
        <h3>JAVA Solution -</h3>
        <div className='description-content-inside description-content-box code'>
            <div>public class InsertionSort &#123;</div>
            <div className='indent-1'>public static void main(String[] args) &#123;</div>
            <div className='indent-2'>int[] arr = &#123;64, 34, 25, 12, 22, 11, 90&#125;;</div>
            <div className='indent-2'>int n = arr.length;</div>
            <div className='indent-2'>insertionSort(arr);</div>
            <div className='indent-2'>System.out.println("Sorted array:");</div>
            <div className='indent-2'>for (int i = 0; i &lt; n; i++) &#123;</div>
            <div className='indent-3'>System.out.print(arr[i] + " ");</div>
            <div className='indent-2'>&#125;</div>
            <div className='indent-1'>&#125;</div>
            <div className='indent-1'>public static void insertionSort(int[] arr) &#123;</div>
            <div className='indent-2'>int n = arr.length;</div>
            <div className='indent-2'>for (int i = 1; i &lt; n; i++) &#123;</div>
            <div className='indent-3'>int key = arr[i];</div>
            <div className='indent-3'>int j = i - 1;</div>
            <div className='indent-3'>// Move elements of arr[0..i-1] that are greater than key to one position ahead of their current position</div>
            <div className='indent-3'>while (j &gt;= 0 && arr[j] &gt; key) &#123;</div>
            <div className='indent-4'>arr[j + 1] = arr[j];</div>
            <div className='indent-4'>j = j - 1;</div>
            <div className='indent-3'>&#125;</div>
            <div className='indent-3'>arr[j + 1] = key;</div>
            <div className='indent-2'>&#125;</div>
            <div className='indent-1'>&#125;</div>
            <div>&#125;</div>
        </div>
    </div>
)