import './ListOfAlgorithms.css'
import { HeaderHomePage } from '../../components/Header/HeaderHomePage';
import { AlgorithmCard } from './AlgorithmCard';

import Sorting from './images/sorting.png'
import Erosion from './images/erosion.png'
import Dilation from './images/dilation.png'
import Opening from './images/opening.png'
import Closing from './images/closing.png'
import Tree from './images/tree.png'
import MinimumPathSum from './images/minimum-path-sum.png'
import { MenuItemsForMobile } from '../../components/Header/SubComponents/MenuItemsForMobile';
import { useSelector } from 'react-redux';

const algorithmsData =[
    {
        title: 'Bubble Sort',
        tags: ['Sorting','Easy'],
        animationLink: '/bubble-sort',
        image: Sorting
    }, {
        title: 'Selection Sort',
        tags: ['Sorting', 'Easy'],
        animationLink: '/selection-sort',
        image: Sorting
    }, {
        title: 'Insertion Sort',
        tags: ['Sorting','Easy'],
        animationLink: '/insertion-sort',
        image: Sorting
    }, {
        title: 'Minimum Path Sum',
        tags: ['Array','Dynamic Programming','Matrix'],
        animationLink: '/minimum-path-sum',
        image: MinimumPathSum
    }, {
        title: 'Dilation',
        tags: ['Image Processing','Morphology'],
        animationLink: 'https://animation.geekosophers.com/Dilation/Dilation%20Animation%20Js/index.html',
        image: Dilation
    }, {
        title: 'Erosion',
        tags: ['Image Processing','Morphology'],
        animationLink: 'https://animation.geekosophers.com/Erosion/Erosion%20Animation%20Js/index.html',
        image: Erosion
    }, {
        title: 'Opening',
        tags: ['Image Processing','Morphology'],
        animationLink: 'https://animation.geekosophers.com/Opening/Opening%20Animation%20Js/index.html',
        image: Opening
    }, {
        title: 'Closing',
        tags: ['Image Processing','Morphology'],
        animationLink: 'https://animation.geekosophers.com/Closing/Closing%20Animation%20Js/index.html',
        image: Closing
    }, {
        title: 'Finding node with minimum value in BST',
        tags: ['BST','Tree'],
        animationLink: '/minimum-node',
        image: Tree
    }, {
        title: 'Finding Least Common Ancestor in BST',
        tags: ['BST','Tree'],
        animationLink: '/lowest-common-ancestor',
        image: Tree
    }, {
        title: 'In Order Tree Traversal',
        tags: ['BST', 'Tree'],
        animationLink: '/in-order-traversal',
        image: Tree
    }, {
        title: 'Pre Order Tree Traversal',
        tags: ['BST', 'Tree'],
        animationLink: '/pre-order-traversal',
        image: Tree
    }, {
        title: 'Post Order Tree Traversal',
        tags: ['BST', 'Tree'],
        animationLink: '/post-order-traversal',
        image: Tree
    }
]

export const ListOfAlgorithms = () => {

    const mobileMenuState = useSelector(state => state.mobileMenuState)

    return(
        <div style={{ height: 'fit-content' }}>
            <HeaderHomePage />
            {   
                !mobileMenuState ?
                <div className='my-4' style={{ width: '80%', margin: 'auto' }}>
                    <h3 style={{ color: '#4B5399' }}>List Of Algorithms</h3>
                    {
                        algorithmsData.map( algorithmData => 
                            <AlgorithmCard 
                                title={algorithmData.title}
                                tags={algorithmData.tags}
                                animationLink={algorithmData.animationLink}
                                image={algorithmData.image}
                            />    
                        )
                    }
                </div> :
                <MenuItemsForMobile />
            }
        </div>
    )
}


{/* <div className='container'>
    <div className='algorithms-list-container'>
        <div className='algorithms-content-container'>
            <div className='algorithms-list'>
                <h2>List of Algorithm</h2>
                <table>
                    <thead>
                        <tr>
                            <th className='table-header-first-col'>Algorithm</th>
                            <th className='table-header-second-col'></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1. Bubble Sort</td>
                            <td>
                                <Link to="/bubble-sort">
                                    <ListOfPlayIcon />
                                </Link>
                            </td>
                        </tr>
                        <tr>
                            <td>2. Selection Sort</td>
                            <td>
                                <Link to="/selection-sort">
                                    <ListOfPlayIcon />
                                </Link>
                            </td>
                        </tr>
                        <tr>
                            <td>3. Insertion Sort</td>
                            <td>
                                <Link to="/insertion-sort">
                                    <ListOfPlayIcon />  
                                </Link>
                            </td>
                        </tr>
                        <tr>
                            <td>4. Minimum Path Sum</td>
                            <td>
                                <Link to="/minimum-path-sum">
                                    <ListOfPlayIcon />  
                                </Link>
                            </td>
                        </tr>
                        <tr>
                            <td>5. Dilation</td>
                            <td>
                                <Link to="https://animation.geekosophers.com/Dilation/Dilation%20Animation%20Js/index.html">
                                    <ListOfPlayIcon />
                                </Link>
                            </td>
                        </tr>
                        <tr>
                            <td>6. Erosion</td>
                            <td>
                                <Link to="https://animation.geekosophers.com/Erosion/Erosion%20Animation%20Js/index.html">
                                    <ListOfPlayIcon />
                                </Link>
                            </td>
                        </tr>
                        <tr>
                            <td>7. Opening</td>
                            <td>
                                <Link to="https://animation.geekosophers.com/Opening/Opening%20Animation%20Js/index.html">
                                    <ListOfPlayIcon />
                                </Link>
                            </td>
                        </tr>
                        <tr>
                            <td>8. Closing</td>
                            <td>
                                <Link to="https://animation.geekosophers.com/Closing/Closing%20Animation%20Js/index.html">
                                    <ListOfPlayIcon />
                                </Link>
                            </td>
                        </tr>
                        <tr>
                            <td>9. Finding node with minimum value in BST</td>
                            <td>
                                <Link to="/minimum-node">
                                    <ListOfPlayIcon />
                                </Link>
                            </td>
                        </tr>
                        <tr>
                            <td>10. Finding Lowest Common Ancestor in BST</td>
                            <td>
                                <Link to="/lowest-common-ancestor">
                                    <ListOfPlayIcon />
                                </Link>
                            </td>
                        </tr>
                        <tr>
                            <td>11. In Order Tree Traversal</td>
                            <td>
                                <Link to="/in-order-traversal">
                                    <ListOfPlayIcon />
                                </Link>
                            </td>
                        </tr>
                        <tr>
                            <td>12. Pre Order Tree Traversal</td>
                            <td>
                                <Link to="/pre-order-traversal">
                                    <ListOfPlayIcon />
                                </Link>
                            </td>
                        </tr>
                        <tr>
                            <td>13. Post Order Tree Traversal</td>
                            <td>
                                <Link to="/post-order-traversal">
                                    <ListOfPlayIcon />
                                </Link>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div> */}