import { UserIcon } from './UserIcon';
import { HamburgerMenu } from './HamburgerMenu';

import { useState, useEffect } from 'react';

export const HamburgerMenuOrUserIcon = () => {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
      // Function to update windowWidth state when window is resized
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
  
      // Add event listener for window resize
      window.addEventListener('resize', handleResize);
  
      // Remove event listener on component unmount
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <>
        {
            windowWidth < 768 ? <HamburgerMenu /> : <UserIcon />
        }
        </>
    )
}