import { backgroundColorChange1dArray } from '../backgroundAnimation/backgroundColorChange';
import { CURRENT_ITEM,MINIMUM,SUCCESS,AQUA } from '../colorScheme';
import { movePointerToNextPosition } from '../translationAnimation/movePointer/movePointerToNextPosition';
import { swapAnimationAndElements } from '../translationAnimation/swapAnimation/swapAnimationAndElements';

export const selectionSort = (inputArray,timeline) => {
    let n = inputArray.length;
    if(n<1) return;

    for(let i=0;i<n-1;i++){
        var currentMinIndex = i;
        backgroundColorChange1dArray(timeline,inputArray,currentMinIndex,[CURRENT_ITEM,MINIMUM])
        
        for(let j=i+1;j<n;j++){
            backgroundColorChange1dArray(timeline,inputArray,j,[CURRENT_ITEM,AQUA])
            const elem1 = parseInt(inputArray[j].current.childNodes[0].innerHTML);
            const elem2 = parseInt(inputArray[currentMinIndex].current.childNodes[0].innerHTML);
            if(elem1<elem2){
                backgroundColorChange1dArray(timeline,inputArray,currentMinIndex,[AQUA])
                backgroundColorChange1dArray(timeline,inputArray,j,[MINIMUM])
                currentMinIndex=j;
            }
        }
        console.log('inputArray',inputArray)
        if(currentMinIndex!==i){
            swapAnimationAndElements(timeline,inputArray,i,currentMinIndex)
        }

        backgroundColorChange1dArray(timeline,inputArray,i,[SUCCESS])
        movePointerToNextPosition(timeline,'.pointer',1,0)
    }

    backgroundColorChange1dArray(timeline,inputArray,n-1,[SUCCESS])
}