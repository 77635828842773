export const animationStateReducer = (animationState=false, action) => {
    switch(action.type){
        case 'CHANGE_ANIMATION_STATE':
            return !animationState
        case 'CHANGE_ANIMATION_STATE_TO_PAUSE':
            return false
        case 'CHANGE_ANIMATION_STATE_TO_PLAY':
            return true
        default:
            return animationState
    }
}