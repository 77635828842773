export const setPauseStep = (pauseStepNumber) => ({
    type: 'SET_PAUSE_STEP',
    pauseStepNumber
});

export const setStep = (stepNumber) => ({
    type: 'SET_STEP',
    stepNumber
});

export const setNextStep = () => ({
    type: 'NEXT_STEP'
});

export const setPreviousStep = () => ({
    type: 'PREVIOUS_STEP'
});

export const resetStep = () => ({
    type: 'RESET_STEP'
});