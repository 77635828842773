import { Feature } from './Feature'
import { features } from './featuresList'

export const FeaturesSection = () => (
    <div className='container' style={{ height: 'fit-content' }}>
        {
            features.map( feature => (
                <Feature 
                    title={feature.title}
                    content={feature.content}
                    image={feature.image}
                    reverseOrder={feature.reverseOrder}
                />
            ))
        }
    </div>
)