export const bgColorAnimationFlow = (target,color,widthOfEdge,animationDuration) => {
    
    if (!target) {
        return {
            targets: null, 
            duration: animationDuration, 
        };
    }
    
    return {
        targets: target,
        width: ['0rem',`${widthOfEdge}`],
        easing: 'linear',
        height: ['0.5rem','0.5rem'],
        backgroundColor: color,
        duration: animationDuration
    }
}