export const borderColorAnimation = (target,colors,animationDuration) => {
    
    if (!target) {
        return {
            targets: null, 
            duration: animationDuration, 
        };
    }

    const borderAnimationList = [];
    colors.forEach(color => {
        borderAnimationList.push({
            value: color, 
            duration: animationDuration,
        })
    })
    
    return {
        targets: target,
        borderColor: borderAnimationList,
        easing: 'cubicBezier(0.4, 0, 0.2, 1)',
    }
}