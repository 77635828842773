import TreeNode from "./TreeNode";

let i = 0;

export const traverseTreeRefsUtil = (root,listOfNodes) => {
    i = 0;
    traverseTreeRefs(root,listOfNodes)
}

const traverseTreeRefs = (root,listOfNodes) => {
    const [hasLeftChild, hasRightChild] = checkForChildren(root)

    if(hasRightChild){
        i++;
        root.right = new TreeNode(listOfNodes[i])
        traverseTreeRefs(root.right,listOfNodes)
    }

    if(hasLeftChild){
        i++;
        root.left = new TreeNode(listOfNodes[i])
        traverseTreeRefs(root.left,listOfNodes)
    }
}

const checkForChildren = (root) => {
    let hasLeftChild = false;
    let hasRightChild = false;

    for(let i=0;i<root.value.childNodes.length;i++){
        if(root.value.childNodes[i].getAttribute('class').includes('left')){
            hasLeftChild = true
        }
        if(root.value.childNodes[i].getAttribute('class').includes('right')){
            hasRightChild = true
        }
    }

    return [hasLeftChild,hasRightChild]
}