import { PreviousStepIcon } from './svg/PreviousStepIcon';
import { NextStepIcon } from './svg/NextStepIcon';
import { setStep,setPauseStep } from '../../../actions/setStep';
import { useSelector, useDispatch } from 'react-redux';
import { changeAnimationStateToPause } from '../../../actions/changeAnimationState';

export const StepControl = () => {

    const pauseStepNumber = useSelector(state => state.stepState.pauseStepNumber)

    const dispatch = useDispatch();

    const handleNextStep = () => {
        const nextStepNumber = pauseStepNumber + 1
        dispatch(changeAnimationStateToPause())
        dispatch(setStep(nextStepNumber))
        dispatch(setPauseStep(nextStepNumber))
    }

    const handlePreviousStep = () => {
        const previousStepNumber = pauseStepNumber - 1
        dispatch(changeAnimationStateToPause())
        dispatch(setStep(previousStepNumber))
        dispatch(setPauseStep(previousStepNumber))
    }

    return(
        <div className='animation-step-control-container text-nowrap'>
            <span onClick={handlePreviousStep}>
                <PreviousStepIcon />
            </span>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span onClick={handleNextStep}>
                <NextStepIcon />
            </span>
        </div>
    )
}