import { useSelector, useDispatch } from 'react-redux';
import { changeAnimationState } from '../../../actions/changeAnimationState';
import ArrowSvg from './svg/arrow.svg';
import { setVisibilityToggleForCustomInputs } from '../../../actions/setVisibilityToggleForCustomInputs';

export const ControlWindowButtons = () => {

    const animationState = useSelector(state => state.animationState)
    const visibilityToggleForCustomInputs = useSelector(state => state.visibilityToggleForCustomInputs)
    
    const dispatch = useDispatch();

    const toggleCustomInputSection = () => {
        dispatch(setVisibilityToggleForCustomInputs())
    }

    return(
        <div className='control-window-buttons'>
            <button className='custom-inputs-button' onClick={toggleCustomInputSection}>
                <span>Custom Inputs&nbsp;</span>
                <div style={{ transform: visibilityToggleForCustomInputs && 'rotate(180deg)' }}>
                    <img src={ArrowSvg} />
                </div>
            </button>
            <button className='start-animation-button' onClick={() => dispatch(changeAnimationState())}>
                {animationState ? 'Pause Animation' : 'Start Animation'}
            </button>
        </div>
    )
}