import { useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { setAnimationProgress } from "../actions/setAnimationProgress"
import AnimationContext from "../context/AnimationContext";

export const useTimelineUpdate = () => {
  const dispatch = useDispatch();
  const timeline = useContext(AnimationContext);

  useEffect(() => {
    if(timeline){
      const updateAnimation = (anim) => {
        const newProgress = (anim.currentTime / anim.duration) * 100;
        dispatch(setAnimationProgress(newProgress));
      };
  
      timeline.update = updateAnimation;
  
      // Clean up the effect when the component unmounts
      return () => {
        delete timeline.update;
      };
    }
  }, [timeline, dispatch]);
};