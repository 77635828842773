import { useEffect } from "react";
import BinarySearchTree from "../../../util/tree/BinarySearchTree";
import { createEdge } from "../../../util/tree/createEdge";
import { createNodeElement } from "../../../util/tree/createNodeElement";

const BinarySearchTreeComponent = (props) => {

    const xDistFromParent = 30;
    const yDistFromParent = 6;
    const radius = 2;

    const erasePreviousTree = (containerRef) => {
      if(containerRef.current.childNodes.length>0){
        const numberOfNodes = containerRef.current.childNodes.length;
        for(let i=numberOfNodes-1;i>=0;i--){
          containerRef.current.removeChild(containerRef.current.childNodes[i])
        }
      }
    }
  
    useEffect(() => {
      const bst = new BinarySearchTree();
      const inputNodes = props.inputArray;
      inputNodes.map( nodeVal => bst.insert(nodeVal))
      
      erasePreviousTree(props.containerRef)
  
      const visualizeTree = (node, x, y,level) => {
        if (node) {

            const nodeDiv = document.createElement('div');
            
            const theta = Math.atan(xDistFromParent/((2*level)*yDistFromParent))
            const edgeLength = Math.sqrt((xDistFromParent/(2*level))*(xDistFromParent/(2*level)) + yDistFromParent*yDistFromParent) + 'rem'
            const [x1, y1] = [ x + radius, y + radius ]; 

            if (node.left) {
                const leftEdge = createEdge(theta,x1,y1,edgeLength,'left')
                nodeDiv.appendChild(leftEdge);
                const leftEdge2 = createEdge(theta,x1,y1,edgeLength,'left')
                nodeDiv.appendChild(leftEdge2);
                visualizeTree(node.left, x - xDistFromParent/(2*level), y + yDistFromParent, level+1);
            }
  
            if (node.right) {
                const rightEdge = createEdge(theta,x1,y1,edgeLength,'right')
                nodeDiv.appendChild(rightEdge);
                const rightEdge2 = createEdge(theta,x1,y1,edgeLength,'right')
                nodeDiv.appendChild(rightEdge2);
                visualizeTree(node.right, x + xDistFromParent/(2*level), y + yDistFromParent, level+1);
            }

            const nodeElement = createNodeElement(x,y,node.value)
            nodeDiv.appendChild(nodeElement);
            props.containerRef.current.insertBefore(nodeDiv,props.containerRef.current.firstChild)
            
        }
      };
  
      visualizeTree(bst.root, 0, 0, 1);
    }, [props.inputArray]);
  
    return <div ref={props.containerRef}></div>;
};

export default BinarySearchTreeComponent;