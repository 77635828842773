import React from "react"
import { Link } from "react-router-dom"

export const LowestCommonAncestorDescriptionContent = () => (
    <div>
        <h2 className='description-title'>Lowest Common Ancestor</h2>
        <div className='description-content-inside'>In a Binary Search Tree (BST), the Lowest Common Ancestor (LCA) of two nodes p and q is defined as the lowest node in the tree that has both p and q as descendants. This problem is commonly solved by utilizing the properties of BSTs, where nodes in the left subtree of a node x are less than or equal to x, and nodes in the right subtree are greater than or equal to x.</div>
        <h3>Example-1</h3>
        <div className='description-content-inside description-content-box'>
            <div>Input:</div>
            <div><span className='primary-purple'>bst = [4, 2, 7, 1, 3]</span></div>
            <div className='primary-purple'>p = 2</div>
            <div className='primary-purple'>q = 7</div>
            <div>Output: The Lowest Common Ancestor of nodes 2 and 7 in the given BST is<span className='primary-purple'> node 4.</span></div>
        </div>
        <h3>Example-2</h3>
        <div className='description-content-inside description-content-box'>
        <div>Input:</div>
            <div><span className='primary-purple'>bst = [4, 2, 7, 1, 3]</span></div>
            <div className='primary-purple'>p = 2</div>
            <div className='primary-purple'>q = 3</div>
            <div>Output: The Lowest Common Ancestor of nodes 2 and 7 in the given BST is<span className='primary-purple'> node 2.</span></div>
        </div>
        <h3>JAVA Solution -</h3>
        <div className='description-content-inside description-content-box code'>
            <div>public TreeNode lowestCommonAncestor(TreeNode root, TreeNode p, TreeNode q) &#123;</div>
            <div className='indent-1'>if (root == null) &#123;</div>
            <div className='indent-2'>return null;</div>
            <div className='indent-1'>&#125;</div>
            <div className='indent-1'>if (p.val &lt; root.val && q.val &lt; root.val) &#123;</div>
            <div className='indent-2'>return lowestCommonAncestor(root.left, p, q);</div>
            <div className='indent-1'>&#125; else if (p.val &gt; root.val && q.val &gt; root.val) &#123;</div>
            <div className='indent-2'>return lowestCommonAncestor(root.right, p, q);</div>
            <div className='indent-1'>&#125; else &#123;</div>
            <div className='indent-2'>return root;</div>
            <div className='indent-1'>&#125;</div>
            <div>&#125;</div>
        </div>
    </div>
)