export const bgColorAnimationReverseFlow = (target,color,widthOfEdge,animationDuration) => {
    
    if (!target) {
        return {
            targets: null, 
            duration: animationDuration
        };
    }
    
    return {
        targets: target,
        width: [`${widthOfEdge}`,'0rem'],
        easing: 'linear',
        backgroundColor: color,
        duration: animationDuration
    }
}