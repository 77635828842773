import {swapAnimation} from './swapAnimation'
import {swapElements} from '../../swapElements'

export const swapAnimationAndElements = (timeline,inputArray,index1,index2) => {
    let target1 = inputArray[index1].current;
    let target2 = inputArray[index2].current;
    let distX = index2-index1;
    let timeFactor = 1000;
    const width = 99.2;
    const height = 99.2;
    swapAnimation(timeline,target1,target2,distX,width,height,timeFactor)
    swapElements(inputArray,index1,index2);
}