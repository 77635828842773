import { StepControl } from './StepControl';
import { ControlWindowButtons } from './ControlWindowButtons';

export const AnimationControlsContainer = () =>{
        
    return (
        <div className='animation-controls-container'>
            <StepControl />
            <ControlWindowButtons />
        </div>
    )
}