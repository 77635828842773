import React, {useState,useEffect,useRef} from 'react';
import { DescriptionWindow } from '../../components/DescriptionWindow/DescriptionWindow';
import { DescriptionWidthHandler } from '../../components/DescriptionWidthHandler/DescriptionWidthHandler';
import { AnimationWindow } from '../../components/AnimationWindow/AnimationWindow';
import { Header } from '../../components/Header/Header';

export const AnimationsPage = () => {
    
    const [isFullscreen, setIsFullscreen] = React.useState(false);
    const [size, setSize] = useState({ x: 300 });
    const ref = useRef();

    useEffect(() => {
        const onFullscreenChange = () => {
          setIsFullscreen(Boolean(document.fullscreenElement));
        }
              
        document.addEventListener('fullscreenchange', onFullscreenChange);
    
        return () => document.removeEventListener('fullscreenchange', onFullscreenChange);
    }, []);

    const handleFullScreen = () => {
        if(isFullscreen) document.exitFullscreen();
        else ref.current.requestFullscreen();
      }

    return (
      <div className='container-app'>
        <Header />
        <div 
            className='content-container' 
            style={{ gridTemplateColumns: size.x<150 ? '10px 1fr 1fr' : `${size.x}px 10px 1fr 1fr` }}
            ref={ref}
        >
            <DescriptionWindow size={size} />
            <DescriptionWidthHandler setSize={setSize} size={size} />
            <AnimationWindow 
              size={size} 
              handleFullScreen={handleFullScreen}
              isFullscreen={isFullscreen} 
            />
        </div>
      </div>
    )
}