export const Feature = (props) => (
    <div className='row my-4'>
        <div className={`col-md features-content-section ${props.reverseOrder && 'order-md-2'} `}>
            <h3 className='hero-title'>{props.title}</h3>
            <div className='features-content'>{props.content}</div>                    
        </div>
        <div className={`col-md features-image-section ${props.reverseOrder && 'order-md-1'}`}>
            <img src={props.image} alt={props.image} style={{ maxWidth: '70%' }}/>
        </div>
    </div>
)