import React from "react"
import { Link } from "react-router-dom"

export const PostOrderDescriptionContent = () => (
    <div>
        <h2 className='description-title'>Post-Order Traversal</h2>
        <div className='description-content-inside'>Tree traversal is a common operation used to visit and process all the nodes in a binary tree. In post-order traversal, nodes are visited in a sequence where each node's children are processed before the node itself. The left and right subtrees are processed first, followed by the root.</div>
        <h3>Example-1</h3>
        <div className='description-content-inside description-content-box'>
            <div>Input: <span className='primary-purple'>bst = [3, 2, 4]</span></div>
            <div>Output: <span className='primary-purple'>[2, 4, 3]</span></div>
        </div>
        <h3>Example-2</h3>
        <div className='description-content-inside description-content-box'>
            
            <div>Input: <span className='primary-purple'>bst = [5, 3, 8, 1, 4, 6, 9]</span></div>
            <div>Output: <span className='primary-purple'>[1, 4, 3, 6, 9, 8, 5]</span></div>
        </div>
        <h3>JAVA Solution -</h3>
        <div className='description-content-inside description-content-box code'>
            <div>public class BinaryTreeTraversal &#123;</div>
            <div className='indent-1'>public void postOrderTraversal(TreeNode root) &#123;</div>
            <div className='indent-2'>if (root != null) &#123;</div>
            <div className='indent-3'>postOrderTraversal(root.left);</div>
            <div className='indent-3'>postOrderTraversal(root.right);</div>
            <div className='indent-3'>System.out.print(root.val + " ");</div>
            <div className='indent-2'>&#125;</div>
            <div className='indent-1'>&#125;</div>
            <div>&#125;</div>
        </div>
        <h3>Related Topics -</h3>
        <div className='description-content-inside code' >
            <Link to='/pre-order-traversal' className="related-topic">Pre-Order Traversal</Link>
            <Link to='/in-order-traversal' className="related-topic">In-Order Traversal</Link>
        </div>
    </div>
)