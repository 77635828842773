import { HeaderHomePage } from "../../components/Header/HeaderHomePage"

export const AboutUsPage = () => {
    return (
        <div style={{ overflowX: 'hidden', height: 'fit-content', textAlign: 'justify' }}>
            <HeaderHomePage />
            <div className='container my-4'>
                <div className="row justify-content-center m-auto">
                    <div className="col-lg-8 col-md-10 col-sm-12">
                        <h2 className='sophers-color'>About <span className='geeko-color'>VisuaLearn</span></h2>
                        <p>Welcome to our interactive animations project! Our platform offers an immersive learning experience through dynamic visualisations of algorithms and data structures. Dive into the world of computer science with algorithm highlighting, custom inputs for experimentation, real-time status updates, and comprehensive descriptions. Whether you're a seasoned developer or just starting your coding journey, our platform provides a user-friendly interface and powerful tools for exploring and understanding complex concepts. Join us and embark on a journey of interactive learning and discovery!</p>
                        <h2 className='sophers-color'>Vision</h2>
                        <p>To create the premier interactive animations platform for the web, revolutionising learning experiences in data structures and algorithms.</p>
                        <p>Benefits of Interactive Animations for Learning:</p>
                        <p>- Facilitates active learning</p>
                        <p>- Tailored for kinesthetic and visual learners</p>
                        <p>- Aligns with the Learning Pyramid, optimising knowledge retention through engagement.</p>
                        <h2 className='sophers-color'>Mission</h2>
                        <p>Simplifying complexity through captivating visuals and interactive experiences, empowering audiences to grasp intricate concepts effortlessly.</p>
                        <h2 className='sophers-color'>Meet the developer</h2>
                        <p>Meet Anshul, a reflective individual driven by a passion for development. With expertise in web development and a portfolio boasting over 20 projects, Anshul embraces the belief that organised knowledge is power. Dedicated and studious, Anshul's pursuits extend beyond coding, with a penchant for sketching and playing the piano. Explore Anshul's journey through a diverse range of personal projects and contributions to the tech community.</p>
                        <p>View Portfolio- <a href='https://www.notion.so/Hello-I-m-Anshul-11949af8ee264bc69315dacbcff673c2?pvs=4'>link</a></p>
                    </div>
                </div>
            </div>
        </div>
    )
}