export const changeText = (timeline,target,originalText,newText,animationDuration,offset) => {
    timeline.add({
        targets: target,
        duration: animationDuration,
        update: function(anim) {
            var progress = anim.progress;
            if (progress < 50) {
                if(originalText !==null)
                    target.innerHTML = originalText;
            } else {
              target.innerHTML = newText;
            }
          }
    }, offset)
}