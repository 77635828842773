export const SelectionSortAnimationContent = () => (
    <div>
        <h2 className='description-title'>Selection Sort</h2>
        <div className='description-content-inside description-content-box code'>
            <div className='procedure-line'>procedure <span className='primary-purple'>selectionSort</span>(arr: array of elements)</div>
            <div className='indent-1'><span className='success-box'>n = length(arr)</span></div>
            <div className='outer-for-loop indent-1'><span className='error-box'>for i = <span className='primary-pink'>0</span> to n - <span className='primary-pink'>2</span></span></div>
            <div className='indent-2'><span className='in-progress-box'>minIndex = i</span></div>
            <div className='inner-for-loop indent-2'>for j = i + <span className='primary-pink'>1</span> to n - <span className='primary-pink'>1</span></div>
            <div className='if-loop indent-3'>if arr[j] &lt; arr[minIndex]</div>
            <div className='indent-4'>minIndex = j</div>
            <div className='indent-2'>swap(arr[minIndex], arr[i])</div>
            <div className='indent-1'>end for</div>
            <div>end procedure</div>
        </div>
    </div>
)