import One from '../images/1.png';
import Two from '../images/2.png';
import Three from '../images/3.png';
import Four from '../images/4.png';
import Five from '../images/5.png';
import Six from '../images/6.png';

export const SecondSectionForMobile = () => (
    <div className="hide-display-in-laptop" style={{ height: 'fit-content' }}>
        <div class="container-fluid">
            <div style={{ overflowX: 'auto' }}>
                <div className='row flex-nowrap overflow-auto' >
                    <div className="col window" style={{ flex: '0 0 auto', width: '15rem', height: '12rem' }}>
                        <img src={One} alt={"1png"} />
                    </div>
                    <div className="col window" style={{ flex: '0 0 auto', width: '15rem', height: '12rem' }}>
                        <img src={Two} alt={"1png"} />
                    </div>
                    <div className="col window" style={{ flex: '0 0 auto', width: '15rem', height: '12rem' }}>
                        <img src={Three} alt={"1png"} />
                    </div>
                    <div className="col window" style={{ flex: '0 0 auto', width: '15rem', height: '12rem' }}>
                        <img src={Four} alt={"1png"} />
                    </div>
                    <div className="col window" style={{ flex: '0 0 auto', width: '15rem', height: '12rem' }}>
                        <img src={Five} alt={"1png"} />
                    </div>
                    <div className="col window" style={{ flex: '0 0 auto', width: '15rem', height: '12rem' }}>
                        <img src={Six} alt={"1png"} />
                    </div>
                </div>
            </div>
        </div>
    </div>
)