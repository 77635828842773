export const bgColorAnimation = (target,colors,animationDuration) => {

    if (target===null || target.current===null) {
        return {
            targets: null, 
            duration: animationDuration*colors.length, 
        };
    }

    const bgAnimationList = [];
    colors.forEach(color => {
        bgAnimationList.push({
            value: color, 
            duration: animationDuration,
        })
    })
    
    return {
        targets: target,
        backgroundColor: bgAnimationList,
        easing: 'cubicBezier(0.4, 0, 0.2, 1)',
    }
}