import { createRef, useEffect, useState } from 'react'
import { useHandleAnimation } from '../../../../customHooks/useHandleAnimation'
import { useDispatch, useSelector } from 'react-redux'
import { usePlayPauseAnimation } from '../../../../customHooks/usePlayPauseAnimation'
import { useTimelineUpdate } from '../../../../customHooks/useTimelineUpdate'
import { useStepControlAnimation } from '../../../../customHooks/useStepControlAnimation'
import { setCustomInput } from '../../../../actions/setCustomInput'
import './MinimumPathSum.css'

export const MinimumPathSumAnimation = () => {

    const customInput = useSelector(state => state.customInput)
    const [inputArray, setInputArray] = useState([]);
    const statusInfoState = useSelector(state => state.statusInfoState)
    const referencesForInputMatrix = (Array.isArray(inputArray) && inputArray.every(Array.isArray)) ? inputArray.map((row, rowIndex) =>
            row.map(() => createRef())
    ) : null;
    const referencesForResultMatrix = (Array.isArray(inputArray) && inputArray.every(Array.isArray)) ? inputArray.map((row, rowIndex) =>
            row.map(() => createRef())
    ) : null;
    const referenceProcessingElement = createRef();
    const referenceCalculationElement = createRef();
    const dispatch = useDispatch();

    useHandleAnimation({referencesForInputMatrix,referencesForResultMatrix,referenceProcessingElement,referenceCalculationElement})
    usePlayPauseAnimation()
    useTimelineUpdate()
    useStepControlAnimation()

    useEffect(() => {
        if(customInput!=='')
            setInputArray(JSON.parse(JSON.parse(customInput).firstInput))
    },[customInput])

    useEffect(() => {
        dispatch(setCustomInput(JSON.stringify({
            firstInput: '[[1, 3, 1], [1, 5, 1], [4, 2, 1]]'
        })))
    },[])
    
    return(
        <div className='animation-world'>
            {
                statusInfoState &&
                <div className='status-info-box'>
                    <div className='first-line-content'>
                        <b>Processing element: </b><span ref={referenceProcessingElement} id='processing-element' className='sophers-color'></span>
                    </div>
                    <div className='second-line-content'>
                        <b>Calculation: </b><span ref={referenceCalculationElement} id='calulation-element' className='sophers-color'></span>
                    </div>
                </div>
            }
            <div className='centering-container scaling-for-mobile'>
                <div className='box-container left-box-container-margin matrix'>
                {
                    (Array.isArray(inputArray) && inputArray.every(Array.isArray)) && 
                    inputArray.map((row, rowIndex) => (
                          row.map((elem, index) => (
                            <div key={index} id={index} ref={referencesForInputMatrix[rowIndex][index]} className='mps-box'>
                                <div className='box-elem'>{elem}</div>
                            </div>
                          ))
                    ))
                }
                <div className='mps-progress-window'></div>
                </div>
                <div className='box-container matrix'>
                {
                    (Array.isArray(inputArray) && inputArray.every(Array.isArray)) && 
                    inputArray.map((row, rowIndex) => (
                          row.map((elem, index) => (
                            <div key={index} id={index} ref={referencesForResultMatrix[rowIndex][index]} className='mps-box'>
                                <div className='box-elem'>0</div>
                            </div>
                          ))
                    ))   
                }
                </div>
            </div>            
        </div>
    )
}