export const InsertionSortAnimationContent = () => (
    <div>
        <h2 className='description-title'>Insertion Sort</h2>
        <div className='description-content-inside description-content-box code'>
            <div className='insertion-procedure-line'>procedure insertionSort(arr: list of comparable elements)</div>
            <div className='indent-1'><span className='success-box'>n = length(arr)</span></div>
            <div className='insertion-for-loop indent-1'><span className='error-box'>for i = <span className='primary-pink'>1</span> to n - <span className='primary-pink'>1</span> do</span></div>
            <div className='indent-2'>key = arr[i]</div>
            <div className='indent-2'>j = i - <span className='primary-pink'>1</span></div>
            <div className='indent-2'>// Move elements of arr[0..i-1] that are greater than key to one position ahead of their current position</div>
            <div className='insertion-while-loop indent-2'>while j &gt;= <span className='primary-pink'>0</span> and arr[j] &gt; key do</div>
            <div className='indent-3'><span className='in-progress-box'>arr[j + 1] = arr[j]</span></div>
            <div className='indent-3'>j = j - 1</div>
            <div className='indent-2'>end while</div>
            <div className='indent-2'>arr[j + 1] = key</div>
            <div className='indent-1'>end for</div>
            <div>end procedure</div>
        </div>
    </div>
)