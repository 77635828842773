import { backgroundColorChange1dArray } from "../backgroundAnimation/backgroundColorChange";
import { SUCCESS,AQUA,MINIMUM } from "../colorScheme";
import { moveAnimation } from "../translationAnimation/moveAnimation/moveAnimation";
import { movePointerToNextPosition } from "../translationAnimation/movePointer/movePointerToNextPosition";

export const insertionSort = (inputArray,timeline) => {
    let n = inputArray.length;

    for (let i = 1; i < n; i++) {
        let key = inputArray[i];        
        let j = i - 1;
        movePointerToNextPosition(timeline,'.pointer',1,0)
        backgroundColorChange1dArray(timeline,inputArray,i,[MINIMUM])
        moveAnimation(timeline,inputArray,0,1,i)

        while (j >= 0 && parseInt(inputArray[j].current.childNodes[0].innerHTML) > parseInt(key.current.childNodes[0].innerHTML)) {
            moveAnimation(timeline,inputArray,1,0,j)
            inputArray[j + 1] = inputArray[j];
            j = j - 1;
        }
        inputArray[j + 1] = key;
        moveAnimation(timeline,inputArray,-(i-(j+1)), -1,j+1)
        backgroundColorChange1dArray(timeline,inputArray,j+1,[AQUA])
    }
    for(let i = 0; i < n; i++){
        backgroundColorChange1dArray(timeline,inputArray,i,[SUCCESS])
    }
}