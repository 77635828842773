import { Link } from 'react-router-dom';

export const FirstSection = () => (
    <div className='first-section'>
        <h2 className='hero-title'>Learn Algorithms like never before!</h2>
        <div className='first-section-content'>
            <div>Experience the magic of algorithms through interactive animations that make learning fun and engaging.</div>
        </div>                    
        <Link to="/algorithms" className='get-started-button'>Get Started</Link>
    </div>
)