export const changeAnimationState = () => ({
    type: 'CHANGE_ANIMATION_STATE'
});

export const changeAnimationStateToPause = () => ({
    type: 'CHANGE_ANIMATION_STATE_TO_PAUSE'
});

export const changeAnimationStateToPlay = () => ({
    type: 'CHANGE_ANIMATION_STATE_TO_PLAY'
});