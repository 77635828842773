import { configureStore } from '@reduxjs/toolkit'
import { animationStateReducer } from '../reducers/animationState'
import { progressBarReducer } from '../reducers/progressBar'
import { restartAnimationReducer } from '../reducers/restartAnimation'
import { customInputReducer } from '../reducers/customInput'
import { stepStateReducer } from '../reducers/stepState'
import { statusInfoStateReducer } from '../reducers/statusInfoState'
import { visibilityToggleForCustomInputsReducer } from '../reducers/visibilityToggleForCustomInputs'
import { algorithmComponentVisibilityReducer } from '../reducers/algorithmComponentVisibility'
import { mobileMenuReducer } from '../reducers/mobileMenuState'

export default configureStore({
    reducer: {
        animationState: animationStateReducer,
        progressBar: progressBarReducer,
        restartAnimation: restartAnimationReducer,
        customInput: customInputReducer,
        stepState: stepStateReducer,
        statusInfoState: statusInfoStateReducer,
        visibilityToggleForCustomInputs: visibilityToggleForCustomInputsReducer,
        algorithmComponentVisibility : algorithmComponentVisibilityReducer,
        mobileMenuState: mobileMenuReducer
    }
})