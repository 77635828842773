import { backgroundColorChange1dArray } from "../backgroundAnimation/backgroundColorChange";
import { SUCCESS,AQUA,MINIMUM } from "../colorScheme";
import { movePointerToNextPosition } from "../translationAnimation/movePointer/movePointerToNextPosition";
import { swapAnimationAndElements } from "../translationAnimation/swapAnimation/swapAnimationAndElements";

export const bubbleSort = (inputArray,timeline) => {
    let n = inputArray.length;
    let swapped;

    if(n<1) return;
    
    for (let i = 0; i < n - 1; i++) {
        swapped = false;
        for (let j = 0; j < n - i - 1; j++) {
            backgroundColorChange1dArray(timeline,inputArray,j,[MINIMUM])
            backgroundColorChange1dArray(timeline,inputArray,j+1,[MINIMUM])
            if (inputArray[j].current.childNodes[0].innerHTML > inputArray[j + 1].current.childNodes[0].innerHTML) {
                swapped = true;
                swapAnimationAndElements(timeline,inputArray,j,j+1)
            }
            backgroundColorChange1dArray(timeline,inputArray,j,[AQUA])
            backgroundColorChange1dArray(timeline,inputArray,j+1,[AQUA])
            movePointerToNextPosition(timeline,'.pointer',1,0)
        }
        backgroundColorChange1dArray(timeline,inputArray,n-1-i,[SUCCESS])
        movePointerToNextPosition(timeline,'.pointer',-(n-1-i),0)

        if (!swapped) {
            break;
        }
        
    }
    backgroundColorChange1dArray(timeline,inputArray,0,[SUCCESS])
}