export const movePointer = (target,moveByX,moveByY) => {
    return {
        targets: target,
        translateX: `+=${moveByX*99.2}`,
        translateY: `+=${moveByY*99.2}`,
        duration: 100,
        easing: 'linear'
    }
}

export const moveWindow = (target,moveToX,moveToY,animationDuration) => {
    return {
        targets: target,
        translateX: `${moveToX*99.2}`,
        translateY: `${moveToY*99.2}`,
        duration: animationDuration,
        easing: 'linear'
    }
}