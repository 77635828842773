import { useEffect, useContext } from "react"
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux'
import AnimationContext from "../context/AnimationContext";
import { selectionSort } from '../util/algorithmsAnimation/selectionSort';
import { bubbleSort } from '../util/algorithmsAnimation/bubbleSort';
import { insertionSort } from '../util/algorithmsAnimation/insertionSort';
import { minimumPathSum } from '../util/algorithmsAnimation/minimumPathSum';
import AlgorithmContext from "../context/AlgorithmContext";
import { minimumNode } from "../util/algorithmsAnimation/minimumNode";
import { inOrderTraversal } from "../util/algorithmsAnimation/treeTraversal/inOrderTraversal";
import { preOrderTraversal } from "../util/algorithmsAnimation/treeTraversal/preOrderTraversal";
import { postOrderTraversal } from "../util/algorithmsAnimation/treeTraversal/postOrderTraversal";
import AlgorithmOutputContext from "../context/AlgorithmOutputContext";
import { leastCommonAncestor } from "../util/algorithmsAnimation/leastCommonAncestor";

export const useHandleAnimation = (refs) => {

    const customInput = useSelector(state => state.customInput)
    const algorithmComponentVisibility = useSelector(state => state.algorithmComponentVisibility)
    const stepNumber = useSelector(state => state.stepState.stepNumber)
    const refLines = useContext(AlgorithmContext);
    const algorithmOutputRef = useContext(AlgorithmOutputContext)
    const timeline = useContext(AnimationContext);
    const location = useLocation();
    const { pathname } = location;

    const seekTimeline = (timeline) => {

        if(stepNumber===0 || stepNumber===undefined) {
            timeline.seek(0)
        } else{
            let activeChildren = timeline.children[stepNumber]
            let timelineOffset = activeChildren.timelineOffset
            let duration = activeChildren.duration

            timeline.seek(timelineOffset+duration)
        }
    }

    useEffect(() => {

    if(timeline){
        
        if(pathname === '/selection-sort'){

            selectionSort(refs,timeline)

        } else if(pathname === '/bubble-sort' || pathname === '/'){
            
            bubbleSort(refs,timeline)

        } else if(pathname === '/insertion-sort'){
            
            insertionSort(refs,timeline)

        } else if(pathname === '/minimum-path-sum'){

            if(timeline.children.length==0){
                minimumPathSum(refs,timeline,refLines)
                seekTimeline(timeline)
            }

        } else if(pathname === '/tree-traversal'){

            selectionSort(refs,timeline)

        }
        else if(pathname === '/minimum-node'){

            minimumNode(refs,timeline,refLines)
            seekTimeline(timeline)

        } else if(pathname === '/in-order-traversal'){

            inOrderTraversal(refs,timeline,refLines,algorithmOutputRef)
            seekTimeline(timeline)

        } else if(pathname === '/pre-order-traversal'){

            preOrderTraversal(refs,timeline,refLines,algorithmOutputRef)
            seekTimeline(timeline)

        } else if(pathname === '/post-order-traversal'){

            postOrderTraversal(refs,timeline,refLines,algorithmOutputRef)
            seekTimeline(timeline)

        } else if(pathname === '/lowest-common-ancestor'){

            leastCommonAncestor(refs,timeline,refLines,algorithmOutputRef)
            seekTimeline(timeline)

        }

    }

    },[customInput,timeline,algorithmComponentVisibility])
}