import { useRef, useEffect } from "react";
import { useSelector } from "react-redux";

export const ProgressBar = (props) => {

    const progressBar = useSelector(state => state.progressBar)
    const progressBarRef = useRef();

    useEffect(() => {
        if(progressBarRef.current)
            progressBarRef.current.style.width = `${progressBar}%`;
    },[progressBar])

    return(
        <div className='animation-progress-bar-background'>
            <div>
                <div 
                    className='animation-progress-bar' 
                    style={{width:'0'}} 
                    ref={progressBarRef}
                />
            </div>
        </div>
    )
}