import React, { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { SelectionSortAnimationContent } from "../../pages/AnimationsPage/SortingAlgorithms/SelectionSortPage/Content/SelectionSortAlgorithmContent";
import { BubbleSortAnimationContent } from "../../pages/AnimationsPage/SortingAlgorithms/BubbleSortPage/Content/BubbleSortAlgorithmContent";
import { InsertionSortAnimationContent } from "../../pages/AnimationsPage/SortingAlgorithms/InsertionSortPage/Content/InsertionSortAlgorithmContent";
import { MinimumPathSumAlgorithmContent } from "../../pages/AnimationsPage/MinimumPathSum/Content/MinimumPathSumAlgorithmContent";
import { useDispatch } from "react-redux";
import { changeAlgorithmCoponentVisibility } from "../../actions/changeAlgorithmComponentVisibility";
import { MinimumNodeAnimationContent } from "../../pages/AnimationsPage/TreeDataStructure/MinimumNode/Content/MinimumNodeAlgorithmContent";
import { InOrderAnimationContent } from "../../pages/AnimationsPage/TreeDataStructure/TreeTraversal/InOrder/Content/InOrderAlgorithmContent";
import { PreOrderAnimationContent } from "../../pages/AnimationsPage/TreeDataStructure/TreeTraversal/PreOrder/Content/PreOrderAlgorithmContent";
import { PostOrderAnimationContent } from "../../pages/AnimationsPage/TreeDataStructure/TreeTraversal/PostOrder/Content/PostOrderAlgorithmContent";
import { LowestCommonAncestorAlgorithmContent } from "../../pages/AnimationsPage/TreeDataStructure/LowestCommonAncestor/Content/LowestCommonAncestorAlgorithmContent";

export const AlgorithmContent = () => {
    
    const location = useLocation();
    const { pathname } = location;
    const dispatch = useDispatch();

    useEffect(() => {
        
        dispatch(changeAlgorithmCoponentVisibility())

        return () => {
            dispatch(changeAlgorithmCoponentVisibility())
        }
    },[])
    
    return(
        <div className="description-content-wrapper">
            <div className='description-content'>  
                {pathname === '/selection-sort' && <SelectionSortAnimationContent />}
                {pathname === '/bubble-sort' && <BubbleSortAnimationContent />}
                {pathname === '/insertion-sort' && <InsertionSortAnimationContent />}
                {pathname === '/minimum-path-sum' && <MinimumPathSumAlgorithmContent />}
                {pathname === '/minimum-node' && <MinimumNodeAnimationContent />}
                {pathname === '/in-order-traversal' && <InOrderAnimationContent />}
                {pathname === '/pre-order-traversal' && <PreOrderAnimationContent />}
                {pathname === '/post-order-traversal' && <PostOrderAnimationContent />}
                {pathname === '/lowest-common-ancestor' && <LowestCommonAncestorAlgorithmContent />}
            </div>
        </div>
    )
}