import { bgColorAnimation } from "./bgColorAnimation"
import { bgColorAnimationFlow } from "./bgColorAnimationFlow"
import { bgColorAnimationReverseFlow } from "./bgColorAnimationReverseFlow"

export const backgroundColorChangeFlow = (timeline,target,color,widthOfEdge,animationDuration,offset) => {
    timeline.add(bgColorAnimationFlow(target,color,widthOfEdge,animationDuration),offset)
}

export const backgroundColorChangeReverseFlow = (timeline,target,color,widthOfEdge,animationDuration,offset) => {
    timeline.add(bgColorAnimationReverseFlow(target,color,widthOfEdge,animationDuration),offset)
}

export const backgroundColorChange = (timeline,target,colors,animationDuration,offset) => {
    if(target.current)
        timeline.add(bgColorAnimation(target.current,colors,animationDuration),offset)
    else
        timeline.add(bgColorAnimation(target,colors,animationDuration),offset)
}

export const backgroundColorChange1dArray = (timeline,inputArray,index,colors,animationDuration,offset) => {
    timeline.add(bgColorAnimation(inputArray[index].current,colors,animationDuration),offset)
}

export const backgroundColorChange2dArray = (timeline,inputArray,rowIndex,colIndex,colors,animationDuration,offset) => {
    timeline.add(bgColorAnimation(inputArray[rowIndex][colIndex].current,colors,animationDuration),offset)
}