import { useContext } from "react";
import AlgorithmContext from "../../../../../../context/AlgorithmContext";
import AlgorithmOutputContext from "../../../../../../context/AlgorithmOutputContext";

export const PostOrderAnimationContent = () => {
    
    const refLines = useContext(AlgorithmContext);
    const algorithmOutputRef = useContext(AlgorithmOutputContext)

    return(
        <div>
            <h2 className='description-title'>Post-Order Traversal</h2>
            <h3>Pseudo Code</h3>
            <div className='description-content-inside description-content-box code'>
                <div className='tree-traversal-procedure-line'><span ref={refLines[0]}>procedure <span className='primary-purple'>PostOrderTraversal</span>(node):</span></div>
                <div className='indent-1 post-order-if-left-loop'><span ref={refLines[3]}>if node.left is <span className='primary-pink'>not null</span></span></div>
                <div className='indent-2'><span ref={refLines[4]}>PostOrderTraversal(node.left)</span></div>
                <div className='indent-1 post-order-if-right-loop'><span ref={refLines[6]}>if node.right is <span className='primary-pink'>not null</span></span></div>
                <div className='indent-2'><span ref={refLines[7]}>PostOrderTraversal(node.right)</span></div>
                <div className='indent-1'><span ref={refLines[5]}>Visit(node)</span></div>
                <div><span ref={refLines[8]}>end procedure</span></div>
            </div>
            <h3>Output</h3>
            <input 
                type='text' 
                value=''
                ref={algorithmOutputRef}
                className='output-array-field'
                readOnly
            />
        </div>
    )
}