import './ListOfBlogs.css'
import { HeaderHomePage } from '../../components/Header/HeaderHomePage';
import { BlogCard } from './BlogCard';

import Erosion from './images/erosion.png'
import Dilation from './images/dilation.png'
import Opening from './images/opening.png'
import Closing from './images/closing.png'

import { MenuItemsForMobile } from '../../components/Header/SubComponents/MenuItemsForMobile';
import { useSelector } from 'react-redux';

const blogsData =[
    {
        title: 'Dilation',
        tags: ['Image Processing','Morphology'],
        blogLink: 'https://medium.com/@anshul16/dilation-morphological-operation-image-processing-82d16a619f59',
        image: Dilation
    }, {
        title: 'Erosion',
        tags: ['Image Processing','Morphology'],
        blogLink: 'https://medium.com/@anshul16/erosion-morphological-operation-image-processing-18537f7c66cd',
        image: Erosion
    }, {
        title: 'Opening',
        tags: ['Image Processing','Morphology'],
        blogLink: 'https://medium.com/@anshul16/opening-morphological-operation-image-processing-bbdbe210e3bc',
        image: Opening
    }, {
        title: 'Closing',
        tags: ['Image Processing','Morphology'],
        blogLink: 'https://medium.com/@anshul16/closing-morphological-operation-image-processing-59a0ef6210e3',
        image: Closing
    }
]

export const ListOfBlogs = () => {

    const mobileMenuState = useSelector(state => state.mobileMenuState)

    return(
        <div style={{ height: 'fit-content' }}>
            <HeaderHomePage />
            {   
                !mobileMenuState ?
                <div className='my-4' style={{ width: '80%', margin: 'auto' }}>
                    <h3 style={{ color: '#4B5399' }}>List Of Blogs</h3>
                    {
                        blogsData.map( blogData => 
                            <BlogCard 
                                title={blogData.title}
                                tags={blogData.tags}
                                blogLink={blogData.blogLink}
                                image={blogData.image}
                            />    
                        )
                    }
                </div> :
                <MenuItemsForMobile />
            }
        </div>
    )
}