import './Header.css'
import { GeekosophersTitleAndMenuItems } from './SubComponents/GeekosophersTitleAndMenuItems';
import { GeekosophersLogo } from './SubComponents/GeekosophersLogo';
import { HamburgerMenuOrUserIcon } from './SubComponents/HamburgerMenuOrUserIcon';

export const Header = () => (
  <div className='header'>
    <div className='flex-container'>
        <GeekosophersLogo />
        <GeekosophersTitleAndMenuItems />
        <HamburgerMenuOrUserIcon />
    </div>
  </div>
)