import React from "react"

export const MinimumNodeDescriptionContent = () => (
    <div>
        <h2 className='description-title'>Minimum Node of BST</h2>
        <div className='description-content-inside'>You are given a binary search tree (BST) where each node has a unique integer value. Write a function to find the minimum node in the given BST.</div>
        <h3>Example-1</h3>
        <div className='description-content-inside description-content-box'>
            <div>Input: <span className='primary-purple'>nums = [ 5, 3, 8, 1, 4 ]</span></div>
            <div>Output: <span className='primary-purple'>Node with value 1</span></div>
        </div>
        <h3>Example-2</h3>
        <div className='description-content-inside description-content-box'>
            
            <div>Input: <span className='primary-purple'>nums = [10, 7, 15, 12, 20, 11]</span></div>
            <div>Output: <span className='primary-purple'>Node with value 7</span></div>
        </div>
        <h3>JAVA Solution -</h3>
        <div className='description-content-inside description-content-box code'>
            <div>class TreeNode &#123;</div>
            <div className='indent-1'>int val;</div>
            <div className='indent-1'>TreeNode left;</div>
            <div className='indent-1'>TreeNode right;</div>
            <div className='indent-1'>public TreeNode(int val) &#123;</div>
            <div className='indent-2'>this.val = val;</div>
            <div className='indent-2'>this.left = null;</div>
            <div className='indent-2'>this.right = null;</div>
            <div className='indent-1'>&#125;</div>
            <div>&#125;</div>
            <div>public class BSTMinNodeFinder &#123;</div>
            <div className='indent-1'>public TreeNode findMinNode(TreeNode root) &#123;</div>
            <div className='indent-2'>if (root == null) &#123;</div>
            <div className='indent-3'>return null;</div>
            <div className='indent-2'>&#125;</div>
            <div className='indent-2'>TreeNode current = root;</div>
            <div className='indent-2'>while (current.left != null) &#123;</div>
            <div className='indent-3'>current = current.left;</div>
            <div className='indent-2'>&#125;</div>
            <div className='indent-2'>return current;</div>
            <div className='indent-1'>&#125;</div>
            <div>&#125;</div>
        </div>
    </div>
)