import { createRef, useEffect, useState } from 'react'
import './InOrder.css'
import { useHandleAnimation } from '../../../../../../customHooks/useHandleAnimation'
import { useDispatch, useSelector } from 'react-redux'
import { usePlayPauseAnimation } from '../../../../../../customHooks/usePlayPauseAnimation'
import { useTimelineUpdate } from '../../../../../../customHooks/useTimelineUpdate'
import { useStepControlAnimation } from '../../../../../../customHooks/useStepControlAnimation'
import { setCustomInput } from '../../../../../../actions/setCustomInput'
import BinarySearchTreeComponent from '../../../BinarySearchTreeComponent'

export const InOrderAnimation = () => {

    const customInput = useSelector(state => state.customInput)
    const [inputArray, setInputArray] = useState([]);
    const dispatch = useDispatch();
    const containerRef = createRef();

    useHandleAnimation(containerRef)
    usePlayPauseAnimation()
    useTimelineUpdate()
    useStepControlAnimation()

    useEffect(() => {
        if(customInput!=='')
            setInputArray(JSON.parse(JSON.parse(customInput).firstInput))
    },[customInput])

    useEffect(() => {
        dispatch(setCustomInput(JSON.stringify({
            firstInput: '[50,30,70,20,40,60,80,25,32,55,75]'
        })))
    },[])
    
    return(
        <div className='animation-world'>
            <div className='tree-box'>
                <BinarySearchTreeComponent containerRef={containerRef} inputArray={inputArray} />
            </div>            
        </div>
    )
}